import DropDownDepositorItem from '@uikit/dropdown-mobile-depositor/item';
import { useOnClickOutside } from '@uikit/hooks/use-on-click-outside';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import IconVerticalDots from './assets/options-vertical.svg';
import IconDots from './assets/options.svg';
import styles from './styles.module.scss';

interface Item {
  name: string;
  additionalText?: string;
  iconUrl?: string;
  onAction: () => void;
}

interface Props {
  isAbsolute?: boolean;
  isVertical?: boolean;
  style?: React.CSSProperties;
  items?: Array<Item>;
}

const DropDownMobileDepositor = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={classNames(styles.dropdownMobile, props.isAbsolute && styles.dropdownMobileAbsolute)} ref={ref}>
      <div
        className={classNames(styles.buttonDots, props.isVertical && styles.buttonDotsVertical)}
        style={props.style}
        onClick={() => setIsOpen(!isOpen)}>
        {props.isVertical ? <IconVerticalDots /> : <IconDots />}
      </div>

      {isOpen ? (
        <div className={styles.subMenu}>
          {props.items?.map((item: Item, index: number) => {
            return (
              <DropDownDepositorItem
                name={item.name}
                additionalText={item.additionalText}
                iconUrl={item.iconUrl}
                onAction={() => {
                  item.onAction();
                  setIsOpen(false);
                }}
                key={index}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default DropDownMobileDepositor;

import { useWeb3Connect } from '@features/web3/hooks/use-web3-connect';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export function Web3ConnectProvider(props: Props) {
  useWeb3Connect();

  return <>{props.children}</>;
}

import Footer from '@features/../layout/footer';
import Header from '@features/../uikit/header';
import HomeCard from '@features/home/ui/home-card';
import HomeSection from '@features/home/ui/home-section';
import { NetworkName } from '@features/lockup/types';
import { useWeb3 } from '@features/web3/hooks/use-web3';
import { ServiceId } from '@features/web3/types';
import { getChainIdsByService } from '@features/web3/utils/get-chain-ids-by-service';
import { toChainId } from '@features/web3/utils/to-chain-id';
import NavHomeTop from '@layout/nav-home-top';
import { AppState } from '@state';
import { GetStaticProps } from 'next';
import { useSelector } from 'react-redux';
// import styles from '@ui/nav-popup-products/styles.module.scss';

const cards = {
  lunar: {
    title: 'Lunar nodes',
    collateral: '100,000 ABYSS',
    desc: 'Details to be announced soon.',
    buttonTitle: 'Q2-Q3 2022',
    urlImg: '/img/cards/lightnodes.svg',
    isButtonDisabled: true
  },
  solar: {
    title: 'Solar nodes',
    collateral: '500,000 ABYSS',
    desc: 'Details to be announced soon.',
    buttonTitle: 'Q2-Q3 2022',
    urlImg: '/img/cards/lightnodes-copy.svg',
    isButtonDisabled: true
  },
  guilds: {
    title: 'Guilds',
    collateral: '10,000+ ABYSS',
    desc: 'Details to be announced soon.',
    buttonTitle: 'Q2-Q3 2022',
    urlImg: '/img/cards/pure.svg',
    isButtonDisabled: true,
    snippets: [
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  },
  syndicates: {
    title: 'Syndicates',
    collateral: '5,000,000+ ABYSS',
    desc: 'Details to be announced soon.',
    buttonTitle: 'Q2-Q3 2022',
    urlImg: '/img/cards/syndicates.svg',
    isButtonDisabled: true,
    snippets: [
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  },
  daico: {
    title: 'DAICO',
    dopText: 'Smart Contract',
    desc:
      'DAICO is an innovative fundraising model based on Ethereum Smart Contract that merges some of the benefits of Decentralized Autonomous Organizations (DAOs), aimed at upgrading and making the initial ICO concept more transparent.',
    buttonTitle: 'VIEW SOURCE CODE',
    urlImg: '/img/cards/daico.svg',
    isFullBlock: true,
    isLogoButton: true,
    isButtonDisabled: true,
    isTarget: true,
    bgImg: '/img/groupImg.png',
    snippets: [
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  },
  gamingPlatform: {
    title: 'Gaming platform',
    collateral: '',
    desc:
      'Game platform which shares its revenue with users. Earn from an advanced referral system, achievements and other activities!',
    buttonTitle: 'EXPLORE THE ABYSS',
    isButtonDisabled: false,
    isTarget: true,
    urlImg: '/img/cards/abyss.svg',
    buttonHref:
      'https://www.theabyss.com/?utm_source=abyssfinance&utm_medium=mainpage&utm_campaign=theabyss&utm_term=explore'
  },
  marketplace: {
    title: 'Marketplace',
    collateral: '',
    desc:
      'NFT Marketplace with advanced referral system and support of the most popular blockchains with Non-Fungible tokens.',
    buttonTitle: 'Q1 2022',
    buttonTitle2: 'learn more',
    buttonHref2: 'https://medium.com/theabyss/next-stop-nft-marketplace-51c9a23095a7',
    buttonType2: 'white',
    isButtonDisabled: true,
    urlImg: '/img/cards/market.svg',
    snippets: [
      {
        text: 'NFT',
        colorText: '#4782f7',
        backgroundColor: 'rgba(146, 183, 252, .2)'
      }
    ]
  },
  ethereumDepositor: {
    title: 'Ethereum Depositor',
    collateral: '',
    isEthBlock: true,
    bgImg: '/img/cards/eth-bg.svg',
    desc:
      'Abyss Ethereum Depositor is a convenient tool that will help you to make multiple deposits to Ethereum Deposit Contract in one batch.',
    buttonTitle: 'Start now',
    button2Title: 'View source code',
    button3Title: 'Smart contract info',
    buttonHref2: 'https://github.com/abyssfinance/abyss-eth2depositor',
    iconEth: '/img/cards/eth.svg',
    isButtonDisabled: false,
    isFullBlock: false,
    buttonHref: '/eth2depositor',
    isTarget: false,
    isLogoButtonDepositor: true,
    snippets: [
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  },
  freeHosting: {
    title: 'Free Hosting Service',
    collateral: '',
    isEthBlock: false,
    bgImg: '/img/cards/eth-bg.svg',
    desc:
      'Provide liquidity with your DeFi tokens, lock LP tokens and enjoy free unlimited Ethereum hosting service for your validator nodes.',
    buttonTitle: 'Learn more',
    button2Title: 'View source code',
    button3Title: 'Smart contract info',
    buttonHref2: 'https://github.com/abyssfinance/abyss-eth2depositor',
    iconEth: '/img/cards/eth.svg',
    isButtonDisabled: false,
    isFullBlock: true,
    buttonHref: '/hosting',
    isTarget: false,
    snippets: [
      {
        text: 'ETHEREUM',
        colorText: '#fff',
        backgroundColor: '#8a65f7'
      },
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  },
  pulsechainDepositor: {
    title: 'PulseChain Depositor',
    collateral: '',
    isEthBlock: true,
    depositorCurrencyId: 'pulsechain',
    bgImg: '/img/cards/eth-bg.svg',
    desc:
      'Abyss PulseChain Depositor is a convenient tool that will help you to make multiple deposits to PulseChain Deposit Contract in one batch.',
    buttonTitle: 'Start now',
    button2Title: 'View source code',
    button3Title: 'Smart contract info',
    buttonHref2: 'https://github.com/abyssfinance/abyss-plsdepositor',
    iconEth: '/img/cards/eth.svg',
    isButtonDisabled: false,
    isFullBlock: false,
    buttonHref: '/pls-depositor',
    isTarget: false,
    isLogoButtonDepositor: false,
    snippets: [
      {
        text: 'DEFI',
        colorText: '#00bac2',
        backgroundColor: 'rgba(0, 186, 194, .2)'
      }
    ]
  }
};

export default function Home() {
  const lockupNetworkName = useSelector<AppState, NetworkName>(state => state.lockup.networkName);
  const lockupChainId = toChainId(lockupNetworkName);
  const { chainId } = useWeb3();
  const resultChainId = chainId ?? lockupChainId;

  return (
    <div className="home">
      <NavHomeTop />

      <Header />
      {getChainIdsByService(ServiceId.GamePlatform).includes(resultChainId) ||
      getChainIdsByService(ServiceId.Marketplace).includes(resultChainId) ? (
        <HomeSection title={'The Abyss'} id={'Abyss'}>
          <HomeCard data={cards.gamingPlatform} path="/lottie/abyss.json" />
          <HomeCard data={cards.marketplace} path="/lottie/market.json" />
        </HomeSection>
      ) : null}

      <HomeSection title={'DeFi'}>
        <HomeCard data={cards.ethereumDepositor} path="/lottie/depositor.json" />
        <HomeCard data={cards.pulsechainDepositor} path="/lottie/depositor.json" />
      </HomeSection>

      {getChainIdsByService(ServiceId.Hosting).includes(resultChainId) ? (
        <HomeSection title={'DeFi'} isFull={true} noPaddingTop={true}>
          <HomeCard data={cards.freeHosting} path="/lottie/eth.json" />
        </HomeSection>
      ) : null}

      {getChainIdsByService(ServiceId.Masternodes).includes(resultChainId) ? (
        <HomeSection title={'Abyss Masternodes'} id={'Masternodes'}>
          <HomeCard data={cards.lunar} path="/lottie/data.json" />
          <HomeCard data={cards.solar} path="/lottie/darknodes.json" />
        </HomeSection>
      ) : null}

      {getChainIdsByService(ServiceId.Staking).includes(resultChainId) ? (
        <HomeSection title={'Abyss Staking'} id={'Staking'}>
          <HomeCard data={cards.guilds} path="/lottie/pure.json" />
          <HomeCard data={cards.syndicates} path="/lottie/syndicates.json" />
        </HomeSection>
      ) : null}

      {getChainIdsByService(ServiceId.Daico).includes(resultChainId) ? (
        <HomeSection title={'Crowdfunding'} isFull={true}>
          <HomeCard data={cards.daico} path="/lottie/daico.json" />
        </HomeSection>
      ) : null}

      <Footer />
      <img src="/img/bottom.png" alt="" className="img-bottom" />
      <img src="/img/top_mobile.png" alt="" className="img-mobile-top" />
      <img src="/img/footer-river.png" alt="" className="img-mobile-bottom" />
    </div>
  );
}

// need for static html generation
export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {}
  };
};

import { useOnClickOutside } from '@uikit/hooks/use-on-click-outside';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';

type ColorScheme =
  | 'blue'
  | 'green'
  | 'default'
  | 'lightGreen'
  | 'pink'
  | 'lightPink'
  | 'lightBlue'
  | 'lightGold'
  | 'lightGrey'
  | 'lightOrange'
  | 'lightPurple';

interface Props {
  children: React.ReactNode;
  type?: ColorScheme;
  value: number | string;
  disabled?: boolean;
}

const LockDropDown = ({ children, type = 'default', value, disabled }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);

  /**
   *
   */
  function isActive() {
    if (isOpen) {
      switch (type) {
        case 'blue':
          return 'blueActive';
        case 'green':
          return 'greenActive';
        case 'pink':
          return 'pink';
        case 'lightGreen':
          return 'lightGreenActive';
        case 'lightPink':
          return 'lightPinkActive';
        case 'lightBlue':
          return 'lightBlueActive';
        case 'lightGold':
          return 'lightGoldActive';
        case 'lightGrey':
          return 'lightGreyActive';
        case 'lightOrange':
          return 'lightOrangeActive';
        case 'lightPurple':
          return 'lightPurpleActive';
        default:
          return 'defaultActive';
      }
    }

    return '';
  }

  /**
   *
   */
  function changeColorSvg() {
    if (isOpen) {
      return '#ffffff';
    } else {
      switch (type) {
        case 'blue':
          return '#2e50cf';
        case 'green':
          return '#868e9c';
        case 'pink':
          return '#ffffff';
        case 'lightGreen':
          return '#00bac2';
        case 'lightPink':
          return '#ec598c';
        case 'lightBlue':
          return '#5197eb';
        case 'lightGold':
          return '#deb031';
        case 'lightGrey':
          return '#99a6b8';
        case 'lightOrange':
          return '#eeac5c';
        case 'lightPurple':
          return '#8a65f7';
        default:
          return '#222222';
      }
    }
  }

  /**
   *
   */
  useOnClickOutside(ref, () => setIsOpen(false));

  /**
   *
   */
  function open() {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  }

  return (
    <div
      className={classNames(styles.lockDropDown, styles[type], styles[isActive()])}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={open}
      ref={ref}>
      {value}
      {!disabled && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          className={classNames(styles.lockDropDownChildrenImg, isOpen ? styles.rotateIcon : null)}>
          <g fill="none" fillRule="evenodd">
            <g fill={changeColorSvg()} fillRule="nonzero">
              <g>
                <path
                  d="M97.342 19.16c.063.062.098.147.098.236s-.035.174-.098.237l-.707.706c-.06.065-.145.101-.233.101-.089 0-.173-.036-.234-.1l-4.126-4.127c-.094-.094-.147-.221-.147-.354v-.173c0-.133.053-.26.147-.353l4.126-4.127c.06-.064.145-.101.234-.101.088 0 .173.037.233.101l.707.707c.063.062.098.147.098.236 0 .09-.035.174-.098.237l-3.387 3.387 3.387 3.386z"
                  transform="translate(-440 -1091) translate(350 1078) rotate(-90 94.668 15.773)"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
      {isOpen ? <div className={styles.lockDropDownItems}>{children}</div> : null}
    </div>
  );
};

export default LockDropDown;

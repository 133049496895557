import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { Connector } from '@web3-react/types';
import React, { useState } from 'react';
import { connectors } from './connectors';
import { Web3Context } from './context/web3.context';

interface Web3ProviderProps {
  children: React.ReactNode;
}

interface Web3ContextProviderProps {
  children: React.ReactNode;
  setConnector: React.Dispatch<React.SetStateAction<Connector | undefined>>;
}

export function Web3Provider(props: Web3ProviderProps) {
  const [connector, setConnector] = useState<Connector | undefined>();

  return (
    <Web3ReactProvider connectors={connectors} connectorOverride={connector} lookupENS={false}>
      <Web3ContextProvider setConnector={setConnector}>{props.children}</Web3ContextProvider>
    </Web3ReactProvider>
  );
}

function Web3ContextProvider(props: Web3ContextProviderProps) {
  const { connector, hooks } = useWeb3React();

  return (
    <Web3Context.Provider value={{ connector, setConnector: props.setConnector, ...hooks }}>
      {props.children}
    </Web3Context.Provider>
  );
}

import { Web3ChainId, Web3ConnectStatus, Web3WalletType } from '@features/web3/types';
import { AppState } from '@state';
import { useSelector } from 'react-redux';

export function useWeb3() {
  const connectStatus = useSelector<AppState, Web3ConnectStatus>(state => state.web3.connectStatus);
  const walletType = useSelector<AppState, Web3WalletType | null>(state => state.web3.walletType);
  const account = useSelector<AppState, string | null>(state => state.web3.account);
  const chainId = useSelector<AppState, Web3ChainId | null>(state => state.web3.chainId);
  const balance = useSelector<AppState, string | null>(state => state.web3.balance);

  return { connectStatus, walletType, account, chainId, balance };
}

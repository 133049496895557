import { Web3ReactHooks, initializeConnector } from '@web3-react/core';
import type { Web3ReactStore } from '@web3-react/types';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { URLS } from '../chains';
import { Web3ChainId } from '../types';

const SHARED_OPTIONS = {
  projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID!,
  showQrModal: true,
  rpcMap: URLS
};

// const chains = Object.keys(CHAINS).map(chainId => Number(chainId));

export const _walletconnectConnectors: Record<Web3ChainId, [WalletConnect, Web3ReactHooks, Web3ReactStore]> = {
  [Web3ChainId.Mainnet]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.Mainnet) });
  }),
  [Web3ChainId.Holesky]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.Holesky) });
  }),
  [Web3ChainId.BscMainnet]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.BscMainnet) });
  }),
  [Web3ChainId.BscTestnet]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.BscTestnet) });
  }),
  [Web3ChainId.PulsechainMainnet]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.PulsechainMainnet) });
  }),
  [Web3ChainId.PulsechainTestnet]: initializeConnector<WalletConnect>(actions => {
    return new WalletConnect({ actions, options: getOptions(Web3ChainId.PulsechainTestnet) });
  })
};

export const [walletconnectConnector, walletconnectConnectorHooks] = _walletconnectConnectors[Web3ChainId.Mainnet];

function getOptions(chainId: Web3ChainId) {
  return {
    ...SHARED_OPTIONS,
    chains: [chainId]
    // optionalChains: chains.filter(chain => chain !== chainId)
  };
}

import { NetworkName, Web3ChainId } from '@features/web3/types';

/**
 *
 * @param networkName
 */
export function toChainId(networkName: NetworkName): Web3ChainId {
  switch (networkName) {
    case 'mainnet':
      return Web3ChainId.Mainnet;

    case 'holesky':
      return Web3ChainId.Holesky;

    case 'bscMainnet':
      return Web3ChainId.BscMainnet;

    case 'bscTestnet':
      return Web3ChainId.BscTestnet;

    case 'pulsechainMainnet':
      return Web3ChainId.PulsechainMainnet;

    case 'pulsechainTestnet':
      return Web3ChainId.PulsechainTestnet;

    default: {
      throw new Error('UNKNOWN_NETWORK_NAME');
    }
  }
}

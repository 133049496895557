import { modalActions } from '@features/modal/state/actions';
import { coinbaseConnector } from '@features/web3/connectors/coinbase.connector';
import { ledgerConnector } from '@features/web3/connectors/ledger.connector';
import { metamaskConnector } from '@features/web3/connectors/metamask.connector';
import { trezorConnector } from '@features/web3/connectors/trezor.connector';
import { trustWalletConnector } from '@features/web3/connectors/trust-wallet.connector';
import { walletconnectConnector } from '@features/web3/connectors/walletconnect.connector';
import { useWeb3Context } from '@features/web3/context/web3.context';
import { web3Actions } from '@features/web3/state/actions';
import { NotMetaMaskError, TransactionMode, Web3ChainId, Web3WalletType } from '@features/web3/types';
import { SelectAddressModal } from '@features/web3/ui/select-address-modal';
import { SelectNetworkModal } from '@features/web3/ui/select-network-modal';
import { WalletConnectErrorModal } from '@features/web3/ui/wallet-connect-error-modal';
import { classifyError } from '@features/web3/utils/classify-error';
import { isCoinbaseInstalled } from '@features/web3/utils/is-coinbase-installed';
import { isMetamaskInstalled } from '@features/web3/utils/is-metamask-installed';
import { isTrustExtensionInstalled } from '@features/web3/utils/is-trust-extension-installed';
import { AppDispatch, AppState } from '@state';
import { UserAgent, parse } from 'next-useragent';
import { destroyCookie, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { okxWalletConnector } from '../connectors/okx-wallet.connector';
import { WalletConnectModal } from '../ui/wallet-connect-modal';

type DerivationType = 'new' | 'old' | 'metamask';

export function WalletConnectModalContainer() {
  const [error, setError] = useState<{ title: string; description: string } | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const visible = useSelector<AppState, boolean>(state => state.modal.isWalletConnectOpened);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const isManualTransactionAllowed = useSelector<AppState, boolean>(state => state.web3.isManualTransactionAllowed);
  const [step, setStep] = useState<number>(1);
  const [walletType, setWalletType] = useState<Web3WalletType | null>(null);
  const [chainId, setChainId] = useState<Web3ChainId | null>(null);
  const [derivationType, setDerivationType] = useState<DerivationType>('new');
  const [isDerivationSelectOpenedByDefault, setIsDerivationSelectOpenedByDefault] = useState<boolean>(false);
  const [userAgent, setUserAgent] = useState<UserAgent | null>(null);
  const isFirefox = userAgent?.isFirefox || false;
  const isMobile = userAgent?.isMobile || false;
  const context = useWeb3Context();

  /**
   *
   */
  useEffect(() => {
    try {
      if (typeof window !== undefined) {
        setUserAgent(parse(window.navigator.userAgent));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  /**
   *
   */
  function handleClose(wasConnected?: boolean) {
    setStep(1);
    setWalletType(null);
    setChainId(null);
    setError(null);
    setIsDerivationSelectOpenedByDefault(false);
    dispatch(modalActions.close());

    if (walletType === Web3WalletType.Ledger || walletType === Web3WalletType.Trezor) {
      if (wasConnected !== true) {
        destroyCookie(null, 'last_connected');
        dispatch(web3Actions.disconnected());
      }
    }
  }

  /**
   *
   */
  async function handleConnectMetaMask(): Promise<void> {
    try {
      if (isMetamaskInstalled()) {
        context.setConnector(metamaskConnector);
        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.MetaMask }));

        await metamaskConnector.activate();

        destroyCookie(null, 'metamask_state');
        setCookie(null, 'last_connected', Web3WalletType.MetaMask, { path: '/' });
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
        handleClose();
      } else {
        throw new NotMetaMaskError();
      }
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   */
  async function handleConnectCoinbase(): Promise<void> {
    try {
      if (isCoinbaseInstalled()) {
        context.setConnector(coinbaseConnector);
        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.Coinbase }));

        await coinbaseConnector.activate();

        destroyCookie(null, 'metamask_state');
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
        setCookie(null, 'last_connected', Web3WalletType.Coinbase, { path: '/' });
        handleClose();
      } else {
        throw new NotMetaMaskError();
      }
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   */
  async function handleConnectOkxWallet(): Promise<void> {
    try {
      if (isCoinbaseInstalled()) {
        context.setConnector(okxWalletConnector);
        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.OkxWallet }));
        await okxWalletConnector.activate();

        destroyCookie(null, 'metamask_state');
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
        setCookie(null, 'last_connected', Web3WalletType.OkxWallet, { path: '/' });
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
        handleClose();
      } else {
        throw new NotMetaMaskError();
      }
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   */
  async function handleConnectTrustExtension(): Promise<void> {
    try {
      if (isTrustExtensionInstalled()) {
        context.setConnector(trustWalletConnector);
        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.TrustExtension }));

        await trustWalletConnector.activate();

        destroyCookie(null, 'metamask_state');
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
        setCookie(null, 'last_connected', Web3WalletType.TrustExtension, { path: '/' });
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
        handleClose();
      } else {
        throw new NotMetaMaskError();
      }
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   * @param walletType
   */
  async function handleConnectWalletConnect(
    walletType: Web3WalletType.WalletConnect | Web3WalletType.TrustWallet = Web3WalletType.WalletConnect
  ): Promise<void> {
    try {
      context.setConnector(walletconnectConnector);
      dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
      dispatch(web3Actions.connecting({ walletType }));

      setIsHidden(true);

      await walletconnectConnector.activate(Web3ChainId.Mainnet);

      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });

      if (walletType === Web3WalletType.WalletConnect) {
        destroyCookie(null, 'wallet_connect_state');
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
        setCookie(null, 'last_connected', Web3WalletType.WalletConnect, { path: '/' });
      } else if (walletType === Web3WalletType.TrustWallet) {
        destroyCookie(null, 'trust_wallet_state');
        setCookie(null, 'last_connected', Web3WalletType.TrustWallet, { path: '/' });
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      } else {
        setCookie(null, 'last_connected', walletType || '', { path: '/' });
        setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
        setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
      }

      handleClose();
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    } finally {
      setIsHidden(false);
    }
  }

  /**
   *
   */
  function handleClickLedger() {
    setWalletType(Web3WalletType.Ledger);
    setStep(2);
  }

  /**
   *
   * @param chainId
   * @param derivation
   */
  async function handleConnectLedger(
    chainId: Web3ChainId,
    derivation: { type: DerivationType; derivation: string; name: string }
  ) {
    try {
      setDerivationType(derivation.type);

      context.setConnector(ledgerConnector);
      dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
      dispatch(web3Actions.connecting({ walletType: Web3WalletType.Ledger }));

      await ledgerConnector.activate(chainId, derivation.derivation);

      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
      setChainId(chainId);
      setStep(3);
    } catch (err: unknown) {
      console.log(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else if (isFirefox) {
        setError({ title: 'This browser is not supported', description: 'Please use Chromium-based browser.' });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   */
  function disconnect() {
    context.connector.deactivate?.();
    context.connector.resetState();
    setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
    setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
    setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    dispatch(web3Actions.disconnected());
  }

  /**
   *
   */
  function changeDerivation() {
    disconnect();
    setIsDerivationSelectOpenedByDefault(true);
    setStep(2);
  }

  /**
   *
   * @param address
   * @param balance
   */
  async function handleSelectLedgerAddress(address: string, balance: string) {
    dispatch(web3Actions.connected({ chainId: chainId!, account: address }));
    dispatch(web3Actions.setBalance({ balance }));
    handleClose(true);
  }

  /**
   *
   */
  function handleClickTrezor() {
    setWalletType(Web3WalletType.Trezor);
    setStep(2);
  }

  /**
   *
   * @param chainId
   */
  async function handleConnectTrezor(chainId: Web3ChainId) {
    try {
      context.setConnector(trezorConnector);
      dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
      dispatch(web3Actions.connecting({ walletType: Web3WalletType.Trezor }));

      await trezorConnector.activate(chainId);

      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
      setChainId(chainId);
      setStep(3);
    } catch (err: unknown) {
      console.error(err);

      dispatch(web3Actions.disconnected());

      const { title, description, isKnownError } = classifyError(err);

      if (isKnownError) {
        setError({ title, description });
      } else {
        setError({ title: 'Something went wrong', description: 'Please try again later.' });
      }
    }
  }

  /**
   *
   * @param address
   * @param balance
   */
  async function handleSelectTrezorAddress(address: string, balance: string) {
    dispatch(web3Actions.connected({ chainId: chainId!, account: address }));
    dispatch(web3Actions.setBalance({ balance }));
    handleClose(true);
  }

  /**
   *
   */
  function handleClickManualTransaction() {
    dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Manual }));
    handleClose();
  }

  return visible ? (
    error ? (
      <WalletConnectErrorModal error={error} visible={visible} onClose={handleClose} />
    ) : step === 1 ? (
      <WalletConnectModal
        onConnectMetaMask={handleConnectMetaMask}
        onClickLedger={handleClickLedger}
        onClickTrezor={handleClickTrezor}
        onConnectCoinbase={handleConnectCoinbase}
        onConnectTrustExtension={handleConnectTrustExtension}
        onConnectWalletConnect={handleConnectWalletConnect}
        onClickManualTransaction={handleClickManualTransaction}
        onConnectOkxWallet={handleConnectOkxWallet}
        isManualTransactionAllowed={isManualTransactionAllowed}
        visible={visible}
        onClose={handleClose}
        isHidden={isHidden}
        isMobile={isMobile}
      />
    ) : step === 2 ? (
      <SelectNetworkModal
        shouldShowLedgerDerivation={walletType === Web3WalletType.Ledger}
        visible={visible}
        onClose={handleClose}
        onConnectLedger={handleConnectLedger}
        onConnectTrezor={handleConnectTrezor}
        isDerivationSelectOpenedByDefault={isDerivationSelectOpenedByDefault}
      />
    ) : (
      <SelectAddressModal
        chainId={chainId!}
        shouldShowLedgerDerivation={walletType === Web3WalletType.Ledger}
        onSelectLedgerAddress={handleSelectLedgerAddress}
        onSelectTrezorAddress={handleSelectTrezorAddress}
        visible={visible}
        onClose={handleClose}
        derivationType={derivationType}
        changeDerivation={changeDerivation}
      />
    )
  ) : null;
}

import { copy } from '@features/copy/utils/copy';
import { useNotification } from '@uikit/notification/hooks/use-notification';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import CopySvg from './assets/copy.svg';
import styles from './styles.module.scss';

interface Props {
  title?: string;
  value: string;
  className?: string;
  style?: CSSProperties;
  copyButtonStyle?: CSSProperties;
}

export function CopyLink(props: Props) {
  const { pushNotification } = useNotification();

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    try {
      copy(props.value);

      pushNotification({ type: 'success', message: 'Copied successfully' });
    } catch {
      pushNotification({ type: 'failure', message: 'Not copied' });
    }
  }

  return (
    <a
      href="#"
      onClick={handleClick}
      className={classNames(styles.link, 'flex-row flex-start', { [`${props.className}`]: props.className })}
      rel="noreferrer noopener"
      style={props.style}>
      <CopySvg style={{ marginRight: 10, ...props.copyButtonStyle }} />
      {props.title ? props.title : ''}
    </a>
  );
}

import { TransactionMode, Web3ChainId, Web3WalletType } from '@features/web3/types';
import { createAction } from '@reduxjs/toolkit';

/**
 *
 */
const connecting = createAction<{ walletType: Web3WalletType | null; baseDerivationPath?: string }>('web3/connecting');

/**
 *
 */
const connected = createAction<{ account: string; chainId: Web3ChainId }>('web3/connected');

/**
 *
 */
const disconnected = createAction('web3/disconnected');

/**
 *
 */
const setBalance = createAction<{ balance: string }>('web3/setBalance');

/**
 *
 */
const changedAccount = createAction<{ account: string }>('web3/changedAccount');

/**
 *
 */
const changedChainId = createAction<{ chainId: Web3ChainId }>('web3/changedChainId');

/**
 *
 */
const setTransactionMode = createAction<{ transactionMode: TransactionMode }>('web3/setTransactionMode');

/**
 *
 */
export const web3Actions = {
  connecting,
  connected,
  disconnected,
  setBalance,
  changedAccount,
  changedChainId,
  setTransactionMode
};

import { BigNumber } from '@features/format/utils/BigNumber';

/**
 *
 * @param number
 * @param decimalPartLength
 */

export function formatNumber(number: string | number, decimalPartLength: string | number): string {
  if (!number) {
    return '0';
  }

  if (typeof number === 'string') {
    number = number.replace(/,/g, '');
  }

  decimalPartLength = parseInt(String(decimalPartLength));

  number = new BigNumber(number)
    .toFixed(decimalPartLength ?? 8, BigNumber.ROUND_DOWN)
    .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');

  const [integer, fraction] = (number as string).split('.');

  if (integer.length < 5) {
    return fraction ? `${integer}.${fraction}` : `${integer}`;
  }

  return fraction
    ? `${Number(integer).toLocaleString('en-US', { localeMatcher: 'lookup' })}.${fraction}`
    : `${Number(integer).toLocaleString('en-US', { localeMatcher: 'lookup' })}`;
}

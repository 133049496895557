import type { ChainConfig } from './types';
import { Web3ChainId } from './types';

export const CHAINS: ChainConfig = {
  [Web3ChainId.Mainnet]: {
    urls: ['https://ethereum-rpc.publicnode.com'],
    name: 'Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://etherscan.io']
  },
  [Web3ChainId.Holesky]: {
    urls: ['https://ethereum-holesky-rpc.publicnode.com'],
    name: 'Holesky',
    nativeCurrency: {
      name: 'Testnet ETH',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://holesky.etherscan.io']
  },
  [Web3ChainId.BscMainnet]: {
    urls: ['https://bsc-rpc.publicnode.com'],
    name: 'BNB Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB Chain Native Token',
      symbol: 'BNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://bscscan.com']
  },
  [Web3ChainId.BscTestnet]: {
    urls: ['https://bsc-testnet-rpc.publicnode.com'],
    name: 'BNB Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB Chain Native Token',
      symbol: 'tBNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://testnet.bscscan.com']
  },
  [Web3ChainId.PulsechainMainnet]: {
    urls: ['https://pulsechain-rpc.publicnode.com'],
    name: 'PulseChain',
    nativeCurrency: {
      name: 'Pulse',
      symbol: 'PLS',
      decimals: 18
    },
    blockExplorerUrls: ['https://otter.pulsechain.com']
  },
  [Web3ChainId.PulsechainTestnet]: {
    urls: ['https://pulsechain-testnet-rpc.publicnode.com'],
    name: 'PulseChain Testnet v4',
    nativeCurrency: {
      name: 'Test Pulse',
      symbol: 'tPLS',
      decimals: 18
    },
    blockExplorerUrls: ['https://scan.v4.testnet.pulsechain.com']
  }
};

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[(chainId as unknown) as Web3ChainId].urls;

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs;
    }

    return accumulator;
  },
  {}
);

import { useOnClickOutside } from '@uikit/hooks/use-on-click-outside';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import CloseSvg from '../../assets/close.svg';
import styles from './styles.module.scss';

export type OnCloseModal = () => void;

interface Props {
  title: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  onClose: OnCloseModal;
  className?: string;
  dialogClass?: string;
  isDisabledClose?: boolean;
  style?: React.CSSProperties;
  isHidden?: boolean;
}

const bodyDom = typeof window !== 'undefined' ? document.body : null;
const rootDom = typeof window !== 'undefined' ? document.getElementById('__next') : null;
const modalDom = typeof window !== 'undefined' ? document.getElementById('modal') : null;

export function ModalPortal(props: Props) {
  const className = props.className ?? '';
  const ref = useRef(null);
  const hasDescription = Boolean(props.description && props.description.length > 0);

  /**
   *
   */
  useEffect(() => {
    if (rootDom && bodyDom) {
      rootDom.classList.add('blur');
      bodyDom.classList.add('overflow-hidden');
    }

    return () => {
      if (rootDom && bodyDom) {
        rootDom.classList.remove('blur');
        bodyDom.classList.remove('overflow-hidden');
      }
    };
  }, []);

  /**
   *
   */

  useOnClickOutside(ref, handleClose);

  /**
   *
   */
  function handleClose(e: React.MouseEvent): void {
    e.stopPropagation();

    if (!props.isDisabledClose) {
      props.onClose();
    }
  }

  if (modalDom && !props.isHidden) {
    return ReactDOM.createPortal(
      <div className={classNames(styles.modalWrapper, 'flex-column flex-center')}>
        <div className={styles.background} />

        <dialog className={classNames(styles.modal, 'flex-column', props.dialogClass)} ref={ref} style={props.style}>
          <div
            className={classNames(styles.header, 'flex-row flex-space-between', {
              [styles.withDescription]: hasDescription
            })}>
            <h1 className={styles.title}>{props.title}</h1>

            {!props.isDisabledClose && (
              <button className={classNames(styles.close, 'flex-column')} onClick={handleClose}>
                <CloseSvg />
              </button>
            )}
          </div>

          {hasDescription ? <span className={styles.description}>{props.description}</span> : null}

          <div className={classNames(styles.content, { [className]: className })}>{props.children}</div>
        </dialog>
      </div>,
      modalDom
    );
  }

  return <></>;
}

import type { Web3ContextType } from '@web3-react/core';
import type { Connector } from '@web3-react/types';
import React, { createContext, useContext } from 'react';

export const Web3Context = createContext<
  {
    connector: Connector;
    setConnector: React.Dispatch<React.SetStateAction<Connector | undefined>>;
  } & Web3ContextType['hooks']
>({} as any);

export function useWeb3Context() {
  return useContext(Web3Context);
}

import { Trezor } from '@allnodes/trezor-connector';
import { initializeConnector } from '@web3-react/core';
import { URLS } from '../chains';

const MANIFEST = {
  email: process.env.NEXT_PUBLIC_TREZOR_EMAIL!,
  appUrl: process.env.NEXT_PUBLIC_TREZOR_APPURL!
};

export const [trezorConnector, trezorConnectorHooks] = initializeConnector<Trezor>(actions => {
  return new Trezor({
    actions,
    options: {
      pollingInterval: 1000 * 60 * 60 * 24,
      rpcMap: URLS,
      accountFetchingConfigs: {},
      manifest: MANIFEST,
      TrezorConnectWebFactoryAsync: () => import('@trezor/connect-web').then(module => module.default)
    }
  });
});

import { modalActions } from '@features/modal/state/actions';
import { useWeb3Context } from '@features/web3/context/web3.context';
import { useWeb3 } from '@features/web3/hooks/use-web3';
import { web3Actions } from '@features/web3/state/actions';
import { AppDispatch, AppState } from '@state';
import { destroyCookie, setCookie } from 'nookies';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountModal } from '../ui/account-modal';

export function AccountModalContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const visible = useSelector<AppState, boolean>(state => state.modal.isAccountOpened);
  const { account: accountId } = useWeb3();
  const context = useWeb3Context();

  const handleClose = () => {
    dispatch(modalActions.close());
  };

  const handleDisconnect = useCallback(() => {
    context.connector.deactivate?.();
    context.connector.resetState();
    destroyCookie(null, 'last_connected');
    setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
    setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
    setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    dispatch(web3Actions.disconnected());
  }, [setCookie, context]);

  return visible ? (
    <AccountModal visible={visible} onDisconnect={handleDisconnect} onClose={handleClose} accountId={accountId} />
  ) : null;
}

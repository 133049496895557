import { modalActions } from '@features/modal/state/actions';
import { createReducer } from '@reduxjs/toolkit';

/**
 *
 */
export interface ModalState {
  isWalletConnectOpened: boolean;
  isAccountOpened: boolean;
  isLockTokensOpened: boolean;
  isRequestTokensOpened: boolean;
}

/**
 *
 */
const INITIAL_STATE: ModalState = {
  isWalletConnectOpened: false,
  isAccountOpened: false,
  isLockTokensOpened: false,
  isRequestTokensOpened: false
};

/**
 *
 */
export const modalReducer = createReducer<ModalState>(INITIAL_STATE, builder => {
  builder
    .addCase(modalActions.openWalletConnectModal, state => {
      state.isWalletConnectOpened = true;
    })
    .addCase(modalActions.openAccountModal, state => {
      state.isAccountOpened = true;
    })
    .addCase(modalActions.openLockTokens, state => {
      state.isLockTokensOpened = true;
    })
    .addCase(modalActions.openRequestTokens, state => {
      state.isRequestTokensOpened = true;
    })
    .addCase(modalActions.close, state => {
      state.isWalletConnectOpened = false;
      state.isAccountOpened = false;
      state.isLockTokensOpened = false;
      state.isRequestTokensOpened = false;
    });
});

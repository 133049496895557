import { forwardRef } from 'react';

export interface ArrowDownProps {
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export const ArrowDown = forwardRef<SVGSVGElement, ArrowDownProps>((props, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    onClick={props.onClick}
    className={props.className}>
    <g fill="none" fillRule="evenodd">
      <g fill="#868e9c" fillRule="nonzero">
        <g>
          <path
            d="M97.342 19.16c.063.062.098.147.098.236s-.035.174-.098.237l-.707.706c-.06.065-.145.101-.233.101-.089 0-.173-.036-.234-.1l-4.126-4.127c-.094-.094-.147-.221-.147-.354v-.173c0-.133.053-.26.147-.353l4.126-4.127c.06-.064.145-.101.234-.101.088 0 .173.037.233.101l.707.707c.063.062.098.147.098.236 0 .09-.035.174-.098.237l-3.387 3.387 3.387 3.386z"
            transform="translate(-440 -1091) translate(350 1078) rotate(-90 94.668 15.773)"
          />
        </g>
      </g>
    </g>
  </svg>
));

ArrowDown.displayName = 'ArrowDown';

import { SERVICE_DATA } from '@features/web3/constants';
import { ServiceId, Web3ChainId } from '@features/web3/types';

/**
 *
 * @param serviceId
 */
export function getChainIdsByService(serviceId: ServiceId): Web3ChainId[] {
  return SERVICE_DATA[serviceId].chainIds;
}

import { formatWalletBalance } from '@features/format/utils/format-wallet-balance';
import { ModalPortal, OnCloseModal } from '@features/modal/ui/modal';
import { useWeb3Context } from '@features/web3/context/web3.context';
import { ledgerEthereumClientFactoryAsync } from '@features/web3/ledger-ethereum-client-factory-async';
import { Web3ChainId } from '@features/web3/types';
import { makeAddressUrl } from '@features/web3/utils/make-address-url';
import { makeShortAddress } from '@features/web3/utils/make-short-address';
import Button from '@uikit/button';
import { CopyLink } from '@uikit/copy-link';
import { EtherscanLink } from '@uikit/etherscan-link';
import { Icon } from '@uikit/icons';
import classNames from 'classnames';
import { bufferToHex, publicToAddress, toChecksumAddress } from 'ethereumjs-util';
import HDKey from 'hdkey';
import { useEffect, useState } from 'react';
import ContractDataSvg from '../../assets/contract-data.svg';
import styles from './styles.module.scss';

interface Props {
  chainId: Web3ChainId;
  onClose: OnCloseModal;
  visible?: boolean;
  shouldShowLedgerDerivation: boolean;
  onSelectLedgerAddress: (address: string, balance: string) => void;
  onSelectTrezorAddress: (address: string, balance: string) => void;
  derivationType: DerivationType;
  changeDerivation: () => void;
}

type DerivationType = 'new' | 'old' | 'metamask';

export function SelectAddressModal(props: Props) {
  const [addresses, setAddresses] = useState<Record<string, { balance: string; path: string }>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [isNeedToEnableContractData, setIsNeedToEnableContractData] = useState<boolean>(false);
  const context = useWeb3Context();
  const provider = context.useSelectedProvider(context.connector);

  /**
   *
   */
  useEffect(() => {
    // function isNewVersion(version: any): boolean {
    //   if (typeof version !== 'string') {
    //     return false;
    //   }
    //
    //   const [major, minor, patch] = version.split('.').map(s => parseInt(s));
    //
    //   return major > 1 || (major === 1 && minor > 11) || (major === 1 && minor === 11 && patch >= 1);
    // }

    async function load() {
      setIsLoading(true);

      if (props.shouldShowLedgerDerivation) {
        // const ledgerApi = await ledgerEthereumClientFactoryAsync();
        // const { arbitraryDataEnabled, version } = await ledgerApi.getAppConfiguration();
        // const isContractDataEnabled = arbitraryDataEnabled === 1 || isNewVersion(version);
        const isContractDataEnabled = true;

        if (isContractDataEnabled) {
          await loadAddressesLedger(page);
        } else {
          setIsNeedToEnableContractData(true);
        }
      } else {
        await loadAddressesTrezor(page);
      }

      setIsLoading(false);
    }

    load();
  }, [isNeedToEnableContractData]);

  /**
   *
   * @param page
   */
  function handlePageChange(page: number) {
    setPage(page);
    setSelectedAddress(null);
    setIsLoading(true);

    if (props.shouldShowLedgerDerivation) {
      loadAddressesLedger(page).then(() => setIsLoading(false));
    } else {
      loadAddressesTrezor(page).then(() => setIsLoading(false));
    }
  }

  /**
   *
   * @param page
   */
  async function loadAddressesLedger(page: number) {
    const ledgerApi = await ledgerEthereumClientFactoryAsync();
    const addresses: Record<string, { balance: string; path: string }> = {};

    if (props.derivationType === 'old') {
      const { publicKey: rootPublicKey, chainCode } = await ledgerApi.getAddress(`m/44'/60'/0'`, false, true);
      const hdKey = new HDKey();

      hdKey.publicKey = Buffer.from(rootPublicKey, 'hex');
      hdKey.chainCode = Buffer.from(chainCode!, 'hex');

      for (let i = 0; i < 5; i++) {
        const path = `m/${(page - 1) * 5 + i}`;
        const { publicKey } = hdKey.derive(path);
        const address = bufferToHex(publicToAddress(publicKey, true));

        addresses[toChecksumAddress(address)] = { balance: '0', path: '' };
      }
    } else if (props.derivationType === 'new') {
      for (let i = 0; i < 5; i++) {
        const path = `m/44'/60'/${(page - 1) * 5 + i}'`;
        const { publicKey: rootPublicKey, chainCode } = await ledgerApi.getAddress(path, false, true);
        const hdKey = new HDKey();

        hdKey.publicKey = Buffer.from(rootPublicKey, 'hex');
        hdKey.chainCode = Buffer.from(chainCode!, 'hex');

        const { publicKey } = hdKey.derive(`m/0/0`);
        const address = bufferToHex(publicToAddress(publicKey, true));

        addresses[toChecksumAddress(address)] = { balance: '0', path: `m/44'/60'/${(page - 1) * 5 + i}'/0` };
      }
    } else if (props.derivationType === 'metamask') {
      const { publicKey: rootPublicKey, chainCode } = await ledgerApi.getAddress(`m/44'/60'/0'/0`, false, true);
      const hdKey = new HDKey();

      hdKey.publicKey = Buffer.from(rootPublicKey, 'hex');
      hdKey.chainCode = Buffer.from(chainCode!, 'hex');

      for (let i = 0; i < 5; i++) {
        const path = `m/${(page - 1) * 5 + i}`;
        const { publicKey } = hdKey.derive(path);
        const address = bufferToHex(publicToAddress(publicKey, true));

        addresses[toChecksumAddress(address)] = { balance: '0', path: `m/44'/60'/0'/0` };
      }
    }

    await Promise.all(
      Object.keys(addresses).map(async address => {
        addresses[address].balance = await provider!.getBalance(address).then(balance => balance.toString());
      })
    );

    setAddresses(addresses);
  }

  /**
   *
   * @param page
   */
  async function loadAddressesTrezor(page: number) {
    const [trezorSubProvider] = (context.connector as any).providerEngine._providers;
    const { hdKey } = trezorSubProvider._initialDerivedKeyInfo;
    const addresses: Record<string, { balance: string; path: string }> = {};

    for (let i = 0; i < 5; i++) {
      const path = `m/${(page - 1) * 5 + i}`;
      const { publicKey } = hdKey.derive(path);
      const address = bufferToHex(publicToAddress(publicKey, true));

      addresses[toChecksumAddress(address)] = { balance: '0', path: '' };
    }

    await Promise.all(
      Object.keys(addresses).map(async address => {
        addresses[address].balance = await provider!.getBalance(address).then(balance => balance.toString());
      })
    );

    setAddresses(addresses);
  }

  /**
   *
   */
  async function handleSubmit() {
    if (props.shouldShowLedgerDerivation) {
      const [ledgerSubProvider] = (context.connector as any).providerEngine._providers;

      if (props.derivationType !== 'old') {
        ledgerSubProvider.setPath(addresses[selectedAddress!].path);
      }

      props.onSelectLedgerAddress(selectedAddress!, addresses[selectedAddress!].balance);
    } else {
      props.onSelectTrezorAddress(selectedAddress!, addresses[selectedAddress!].balance);
    }
  }

  return props.visible ? (
    <ModalPortal title={isNeedToEnableContractData ? '' : 'Address'} onClose={props.onClose}>
      {isLoading ? (
        <div className={classNames(styles.wrapper, 'flex-column flex-center')}>
          <img src="/img/loader2.svg" alt="" width="80" height="80" />
        </div>
      ) : isNeedToEnableContractData ? (
        <div className="flex-column flex-center" style={{ maxWidth: 490 }}>
          <span className={styles.contractDataTitle}>Enable Blind signing</span>
          <span className={styles.contractDataDescription}>
            To use Abyss Finance services you need to open Ethereum app <b>Settings</b> -&#62; <b>Blind signing</b> on
            your Ledger and set it to <b>Enabled</b>.
          </span>
          <ContractDataSvg className={styles.contractDataImg} />
          <Button type="primary" onClick={() => setIsNeedToEnableContractData(false)} styles={{ width: '100%' }}>
            RETRY
          </Button>
        </div>
      ) : (
        <div className={classNames(styles.wrapper, 'flex-column')}>
          <div className={classNames(styles.headers, 'flex-row flex-space-between')}>
            <div className={classNames(styles.addressHeader)}>ADDRESS</div>
            <div className={classNames(styles.balanceHeader)}>BALANCE</div>
          </div>

          <div className={classNames(styles.addresses, 'flex-column')}>
            {Object.keys(addresses).map((address: string, i: number) => (
              <div
                key={address}
                onClick={() => setSelectedAddress(address)}
                className={classNames(styles.address, 'flex-row flex-space-between', {
                  [styles.addressActive]: address === selectedAddress
                })}>
                <div className="flex-row">
                  <div className={classNames(styles.radio, { [styles.radioActive]: address === selectedAddress })}>
                    <div />
                  </div>
                  <div style={{ width: (page - 1) * 5 + 5 >= 100 ? 50 : 30 }}>{(page - 1) * 5 + i + 1}</div>
                  <div className={styles.addressText}>{makeShortAddress(address, 6)}</div>
                  <CopyLink title="" value={address} style={{ marginRight: 5 }} />
                  <EtherscanLink title="" value={makeAddressUrl(props.chainId, address)} style={{ marginRight: 5 }} />
                </div>

                <div className={classNames(styles.balance, 'flex-row')}>
                  {formatWalletBalance(addresses[address].balance, 'ETH')}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.separator} />

          <div className="flex-row flex-center" style={{ marginBottom: 45 }}>
            <Button
              variant="text"
              className={classNames(styles.button, { [styles.buttonDisabled]: page === 1 })}
              styles={{ marginRight: 30 }}
              onClick={() => page > 1 && handlePageChange(page - 1)}>
              <div className="flex-column flex-center" style={{ height: '100%' }}>
                <Icon className={styles.previousIcon} src={page === 1 ? 'ForwardDisabled' : 'Forward'} />
              </div>
              Previous
            </Button>

            <Button
              variant="text"
              className={classNames(styles.button, { [styles.buttonDisabled]: page === 20 })}
              onClick={() => page < 20 && handlePageChange(page + 1)}>
              Next
              <div className="flex-column flex-center" style={{ height: '100%' }}>
                <Icon className={styles.nextIcon} src={page === 20 ? 'ForwardDisabled' : 'Forward'} />
              </div>
            </Button>
          </div>

          {props.shouldShowLedgerDerivation ? (
            <Button
              type="white"
              onClick={props.changeDerivation}
              styles={{ width: '100%', marginBottom: 10, lineHeight: 1.5 }}
              variant="text">
              Don&#39;t see your address? Click to select other derivation
            </Button>
          ) : null}

          <Button
            type="primary"
            onClick={handleSubmit}
            styles={{ width: '100%' }}
            disabled={selectedAddress == null || addresses[selectedAddress] == null}>
            ACCESS MY WALLET
          </Button>
        </div>
      )}
    </ModalPortal>
  ) : null;
}

import { NetworkName, Web3ChainId } from '@features/web3/types';

/**
 *
 * @param chainId
 */
export function toNetworkName(chainId: Web3ChainId): NetworkName {
  switch (chainId) {
    case Web3ChainId.Mainnet:
      return 'mainnet';

    case Web3ChainId.Holesky:
      return 'holesky';

    case Web3ChainId.BscMainnet:
      return 'bscMainnet';

    case Web3ChainId.BscTestnet:
      return 'bscTestnet';

    case Web3ChainId.PulsechainMainnet:
      return 'pulsechainMainnet';

    case Web3ChainId.PulsechainTestnet:
      return 'pulsechainTestnet';

    default: {
      throw new Error('UNKNOWN_CHAIN_ID');
    }
  }
}

import CoinbaseSvg from './assets/coinbase.svg';
import LedgerSvg from './assets/ledger.svg';
import MetaMaskSvg from './assets/metamask.svg';
import OkxWalletSvg from './assets/okx-wallet.svg';
import TrezorSvg from './assets/trezor.svg';
import TrustWalletSvg from './assets/trust-wallet.svg';
import WalletConnectSvg from './assets/wallet-connect.svg';
import { CurrencyId, NetworkName, ServiceData, ServiceId, Web3ChainData, Web3ChainId, Web3WalletType } from './types';

/**
 *
 */
export const WEB3_CHAIN_IDS = [
  Web3ChainId.Mainnet,
  Web3ChainId.BscMainnet,
  Web3ChainId.BscTestnet,
  Web3ChainId.PulsechainTestnet,
  Web3ChainId.PulsechainMainnet,
  Web3ChainId.Holesky
];

/**
 *
 */
export const SERVICE_DATA: Record<ServiceId, ServiceData> = {
  [ServiceId.Daico]: {
    chainIds: [Web3ChainId.Mainnet, Web3ChainId.BscMainnet, Web3ChainId.BscTestnet]
  },
  [ServiceId.Home]: {
    chainIds: [
      Web3ChainId.Mainnet,
      Web3ChainId.BscTestnet,
      Web3ChainId.BscMainnet,
      Web3ChainId.PulsechainMainnet,
      Web3ChainId.PulsechainTestnet,
      Web3ChainId.Holesky
    ]
  },
  [ServiceId.Dashboard]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Depositor]: {
    chainIds: [Web3ChainId.Mainnet, Web3ChainId.Holesky]
  },
  [ServiceId.PulsechainDepositor]: {
    chainIds: [Web3ChainId.PulsechainMainnet, Web3ChainId.PulsechainTestnet]
  },
  [ServiceId.GamePlatform]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Host]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Hosting]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Lockup]: {
    chainIds: [Web3ChainId.Mainnet, Web3ChainId.BscTestnet, Web3ChainId.BscMainnet]
  },
  [ServiceId.Main]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Marketplace]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Masternodes]: {
    chainIds: [Web3ChainId.Mainnet]
  },
  [ServiceId.Staking]: {
    chainIds: [Web3ChainId.Mainnet]
  }
};

/**
 *
 */
export const WEB3_CHAIN_DATA: Record<Web3ChainId, Web3ChainData> = {
  [Web3ChainId.Mainnet]: {
    chainId: Web3ChainId.Mainnet,
    title: 'Mainnet',
    etherscanBaseUrl: 'https://etherscan.io',
    colorScheme: 'lightGreen',
    currencyId: CurrencyId.Ethereum
  },
  [Web3ChainId.Holesky]: {
    chainId: Web3ChainId.Holesky,
    title: 'Holesky',
    etherscanBaseUrl: 'https://Holesky.etherscan.io',
    colorScheme: 'lightGrey',
    currencyId: CurrencyId.Ethereum
  },
  [Web3ChainId.BscMainnet]: {
    chainId: Web3ChainId.BscMainnet,
    title: 'Mainnet',
    etherscanBaseUrl: `https://bscscan.com`,
    colorScheme: 'lightGold',
    currencyId: CurrencyId.Binance
  },
  [Web3ChainId.BscTestnet]: {
    chainId: Web3ChainId.BscTestnet,
    title: 'Testnet',
    etherscanBaseUrl: `https://testnet.bscscan.com`,
    colorScheme: 'lightGrey',
    currencyId: CurrencyId.Binance
  },
  [Web3ChainId.PulsechainMainnet]: {
    chainId: Web3ChainId.PulsechainMainnet,
    title: 'Mainnet',
    etherscanBaseUrl: `https://scan.pulsechain.com`,
    colorScheme: 'lightBlue',
    currencyId: CurrencyId.Pulsechain
  },
  [Web3ChainId.PulsechainTestnet]: {
    chainId: Web3ChainId.PulsechainTestnet,
    title: 'Testnet',
    etherscanBaseUrl: `https://scan.v4.testnet.pulsechain.com`,
    colorScheme: 'lightGrey',
    currencyId: CurrencyId.Pulsechain
  }
};

/**
 *
 */
export const NETWORK_NAMES: Record<NetworkName, NetworkName> = {
  mainnet: 'mainnet',
  bscMainnet: 'bscMainnet',
  bscTestnet: 'bscTestnet',
  pulsechainMainnet: 'pulsechainMainnet',
  pulsechainTestnet: 'pulsechainTestnet',
  holesky: 'holesky'
};

/**
 *
 */
export const WEB3_WALLET_DATA = {
  [Web3WalletType.MetaMask]: {
    title: 'MetaMask',
    Logo: MetaMaskSvg
  },
  [Web3WalletType.Ledger]: {
    title: 'Ledger',
    Logo: LedgerSvg
  },
  [Web3WalletType.Trezor]: {
    title: 'Trezor',
    Logo: TrezorSvg
  },
  [Web3WalletType.Coinbase]: {
    title: 'Coinbase Wallet',
    Logo: CoinbaseSvg
  },
  [Web3WalletType.TrustExtension]: {
    title: 'Trust Wallet',
    Logo: TrustWalletSvg
  },
  [Web3WalletType.WalletConnect]: {
    title: 'WalletConnect',
    Logo: WalletConnectSvg
  },
  [Web3WalletType.OkxWallet]: {
    title: 'OKX Wallet',
    Logo: OkxWalletSvg
  },
  [Web3WalletType.TrustWallet]: {
    title: 'Trust Wallet',
    Logo: TrustWalletSvg
  }
};

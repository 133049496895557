import { ContractInfoNetwork, Ethereum1NetworkName, Ethereum2NetworkName } from '@features/eth2depositor/types';
import { Web3ChainId } from '@features/web3/types';

/**
 *
 */
export const ETHEREUM_1_NETWORK_NAMES: Record<Ethereum1NetworkName, Ethereum1NetworkName> = {
  mainnet: 'mainnet',
  pulsechainMainnet: 'pulsechainMainnet',
  pulsechainTestnet: 'pulsechainTestnet',
  holesky: 'holesky'
};

/**
 *
 */
export const ETHEREUM_2_NETWORK_NAMES: Record<Ethereum2NetworkName, Ethereum2NetworkName> = {
  mainnet: 'mainnet',
  prater: 'prater',
  holesky: 'holesky'
};

/**
 *
 */
export const DEPOSITOR_ADDRESSES: Record<ContractInfoNetwork, string> = {
  mainnet: '0xFA5f9EAa65FFb2A75de092eB7f3fc84FC86B5b18',
  pulsechainMainnet: '0x2cB1A746A8652dfbb0FC11BdA71Bd991EB2Fd52e',
  pulsechainTestnet: '0x2cB1A746A8652dfbb0FC11BdA71Bd991EB2Fd52e',
  holesky: '0xFA5f9EAa65FFb2A75de092eB7f3fc84FC86B5b18'
};

/**
 *
 */
export const DEPOSITOR_CHAIN_IDS: Web3ChainId[] = [Web3ChainId.Mainnet, Web3ChainId.Holesky];

/**
 *
 */
export const PULSECHAIN_DEPOSITOR_CHAIN_IS: Web3ChainId[] = [
  Web3ChainId.PulsechainMainnet,
  Web3ChainId.PulsechainTestnet
];

import { ModalPortal, OnCloseModal } from '@features/modal/ui/modal';
import classNames from 'classnames';
import ErrorSvg from './assets/error.svg';
import styles from './styles.module.scss';

interface WalletConnectModalProps {
  onClose: OnCloseModal;
  error: { title: string; description: string };
  visible?: boolean;
}

export function WalletConnectErrorModal(props: WalletConnectModalProps) {
  const { error } = props;

  return props.visible ? (
    <ModalPortal title="" onClose={props.onClose}>
      <div className={classNames(styles.error, 'flex-column flex-center')}>
        <ErrorSvg className={styles.img} />
        <h2 className={styles.title}>{error.title}</h2>
        <span className={styles.description}>{error.description}</span>
      </div>
    </ModalPortal>
  ) : null;
}

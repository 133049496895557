import Eth from '@allnodes/hw-app-eth';
import Transport from '@ledgerhq/hw-transport';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { ledgerConnector } from './connectors/ledger.connector';

let transport: Transport | undefined;

export async function ledgerEthereumClientFactoryAsync(): Promise<Eth> {
  transport = await createWebHid(transport);

  transport.on('disconnect', () => {
    console.log('ledger::disconnect');
    ledgerConnector.deactivate();
    clearLedgerEthereumClient();
  });

  return new Eth(transport);
}

export async function ledgerEthereumClientFactoryAsyncAny(): Promise<any> {
  return ledgerEthereumClientFactoryAsync();
}

export function clearLedgerEthereumClient(): void {
  console.log('ledger::clear');

  transport = undefined;
}

async function createWebHid(transport?: Transport): Promise<Transport> {
  console.log('ledger::create');

  if (transport != null) {
    try {
      const [device] = await TransportWebHID.list();

      if (device?.opened) {
        return transport;
      }
    } catch {
      /* empty */
    }

    return (await TransportWebHID.openConnected()) ?? (await TransportWebHID.create());
  }

  return await TransportWebHID.create();
}

import React from 'react';

/**
 *
 */
export enum Media {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Unknown = 'unknown'
}

/**
 *
 */
export interface MediaValue {
  media: Media;
}

/**
 *
 */
export const MediaContext = React.createContext<MediaValue>({
  media: Media.Unknown
});

import { isDev } from '@features/env/utils/is-dev';
import { lockupReducer } from '@features/lockup/state/reducer';
import { modalReducer } from '@features/modal/state/reducer';
import { web3Reducer } from '@features/web3/state/reducer';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

/**
 *
 */
export const reducer = combineReducers({
  lockup: lockupReducer,
  web3: web3Reducer,
  modal: modalReducer
});

/**
 *
 */
export const store = configureStore({
  reducer,
  devTools: isDev(),
  middleware: [...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })] as const
});

/**
 *
 */
export type AppState = ReturnType<typeof store.getState>;

/**
 *
 */
export type AppDispatch = typeof store.dispatch;

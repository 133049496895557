import { DEPOSITOR_CHAIN_IDS, PULSECHAIN_DEPOSITOR_CHAIN_IS } from '@features/eth2depositor/constants';
import { HOST_CHAIN_IDS } from '@features/eth2host/constants';
import { formatWalletBalance } from '@features/format/utils/format-wallet-balance';
import { LOCKUP_CHAIN_IDS } from '@features/lockup/constants';
import { modalActions } from '@features/modal/state/actions';
import { WEB3_CHAIN_DATA, WEB3_CHAIN_IDS } from '@features/web3/constants';
import { useWeb3Context } from '@features/web3/context/web3.context';
import { useWeb3 } from '@features/web3/hooks/use-web3';
import { CurrencyId, TransactionMode, Web3ConnectStatus } from '@features/web3/types';
import { makeShortAddress } from '@features/web3/utils/make-short-address';
import { AppDispatch, AppState } from '@state';
import { Button } from '@uikit/button';
import { useNotification } from '@uikit/notification/hooks/use-notification';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

export function Web3Status() {
  const dispatch = useDispatch<AppDispatch>();
  const { account, chainId, balance, connectStatus } = useWeb3();
  const isWalletConnectOpened = useSelector<AppState, boolean>(state => state.modal.isWalletConnectOpened);
  const transactionMode = useSelector<AppState, TransactionMode>(state => state.web3.transactionMode);
  const isManualTransaction = transactionMode === TransactionMode.Manual;
  const { pushNotification } = useNotification();
  const { pathname } = useRouter();
  const isLockupPage = pathname === '/lockup';
  const isDepositorPage = pathname === '/eth2depositor';
  const isPulsechainDepositorPage = pathname === '/pls-depositor';
  const isHostPage = pathname === '/host';
  const isDashboardPage = pathname === '/dashboard';
  const isUnknownConnectingStatus = connectStatus === Web3ConnectStatus.Unknown;
  const isAutoConnectingMetaMask = connectStatus === Web3ConnectStatus.Connecting && !isWalletConnectOpened;
  const isManualTransactionAllowed = (isDepositorPage || isPulsechainDepositorPage) && !isManualTransaction;
  const context = useWeb3Context();

  const handleClickToOpenAccountModal = useCallback(() => {
    dispatch(modalActions.openAccountModal());
  }, []);

  // useEffect(() => {
  //   if (error && lastError?.message !== error.message) {
  //     setLastError(error);
  //
  //     const { isUnsupportedChainIdError, description } = classifyError(error);
  //
  //     if (isUnsupportedChainIdError) {
  //       pushNotification({ type: 'failure', message: description });
  //       context.connector.deactivate?.();
  //       context.connector.resetState();
  //       setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
  //       setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
  //       setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
  //     }
  //   }
  // }, [error]);

  /**
   *
   */
  useEffect(() => {
    const onlyMainnetMessage = `ONLY MAINNET IS AVAILABLE`;

    if ((isHostPage || isDashboardPage) && account && chainId && !HOST_CHAIN_IDS.includes(chainId)) {
      pushNotification({ type: 'failure', message: onlyMainnetMessage });
      context.connector.deactivate?.();
      context.connector.resetState();
      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    }

    if (isDepositorPage && account && chainId && !DEPOSITOR_CHAIN_IDS.includes(chainId)) {
      pushNotification({ type: 'failure', message: onlyMainnetMessage });
      context.connector.deactivate?.();
      context.connector.resetState();
      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    }

    if (isPulsechainDepositorPage && account && chainId && !PULSECHAIN_DEPOSITOR_CHAIN_IS.includes(chainId)) {
      pushNotification({ type: 'failure', message: `ONLY PULSECHAIN MAINNET/TESTNET ARE AVAILABLE` });
      context.connector.deactivate?.();
      context.connector.resetState();
      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    }

    if (isLockupPage && account && chainId && !LOCKUP_CHAIN_IDS.includes(chainId)) {
      pushNotification({ type: 'failure', message: onlyMainnetMessage });
      context.connector.deactivate?.();
      context.connector.resetState();
      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    }

    if (
      !isHostPage &&
      !isDashboardPage &&
      !isDepositorPage &&
      !isPulsechainDepositorPage &&
      !isLockupPage &&
      account &&
      chainId &&
      !WEB3_CHAIN_IDS.includes(chainId)
    ) {
      pushNotification({ type: 'failure', message: onlyMainnetMessage });
      context.connector.deactivate?.();
      context.connector.resetState();
      setCookie(null, 'metamask_state', 'disconnected', { path: '/' });
      setCookie(null, 'wallet_connect_state', 'disconnected', { path: '/' });
      setCookie(null, 'trust_wallet_state', 'disconnected', { path: '/' });
    }
  }, [account, chainId, pathname]);

  return (
    <>
      {isUnknownConnectingStatus || isAutoConnectingMetaMask ? (
        <li className={styles.wrapper} />
      ) : isManualTransaction ? (
        <li className={styles.wrapper} style={{ width: 'max-content' }}>
          <div className="flex-row flex-start">
            <div className={styles.separator} />

            <span className={styles.manualTransaction}>Manual transaction</span>

            <Button
              type="white"
              className={styles.connect}
              onClick={() => dispatch(modalActions.openWalletConnectModal({ isManualTransactionAllowed }))}>
              CONNECT WALLET
            </Button>
          </div>
        </li>
      ) : account ? (
        <li className={styles.wrapper}>
          <div className="flex-row flex-start">
            <div className={styles.separator} />

            <div
              className={classNames(styles.wallet, 'flex-column flex-space-between')}
              onClick={handleClickToOpenAccountModal}>
              <span className={styles.address}>MY WALLET: {makeShortAddress(account, 3)}</span>
              {typeof balance === 'string' ? (
                <span className={styles.balance}>
                  {formatWalletBalance(
                    balance,
                    chainId && WEB3_CHAIN_DATA[chainId].currencyId === CurrencyId.Pulsechain
                      ? 'PLS'
                      : chainId && WEB3_CHAIN_DATA[chainId].currencyId === CurrencyId.Binance
                      ? 'BNB'
                      : 'ETH'
                  )}
                </span>
              ) : null}
            </div>
          </div>
        </li>
      ) : (
        <li className={classNames(styles.wrapper, 'hidden-mobile')} style={{ marginLeft: 0 }}>
          <Button
            className={styles.connect}
            onClick={() => dispatch(modalActions.openWalletConnectModal({ isManualTransactionAllowed }))}>
            CONNECT WALLET
          </Button>
        </li>
      )}
    </>
  );
}

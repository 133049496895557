/**
 *
 */
const tokenLogos: Record<string, string | null> = {};

/**
 *
 * @param address
 */
export async function makeTokenLogoUrl(address: string): Promise<string | null> {
  try {
    if (tokenLogos[address] !== undefined) {
      return tokenLogos[address];
    }

    const url = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;
    const response = await fetch(url);

    if (response.ok) {
      tokenLogos[address] = url;
    }

    return response.ok ? url : null;
  } catch {
    return null;
  }
}

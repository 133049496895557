import type { AddEthereumChainParameter } from '@web3-react/types';

/**
 *
 */
export enum TransactionMode {
  Auto = 'auto',
  Manual = 'manual'
}

/**
 *
 */
export enum Web3WalletType {
  MetaMask = 'metamask',
  Ledger = 'ledger',
  Trezor = 'trezor',
  WalletConnect = 'wallet-connect',
  Coinbase = 'coinbase',
  TrustWallet = 'trust-wallet',
  TrustExtension = 'trust-extension',
  OkxWallet = 'okx-wallet'
}

/**
 *
 */
export enum Web3ConnectStatus {
  Unknown = 'unknown',
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected'
}

/**
 *
 */
export enum Web3ChainId {
  Mainnet = 1,
  BscMainnet = 56,
  BscTestnet = 97,
  PulsechainMainnet = 369,
  PulsechainTestnet = 943,
  Holesky = 17000
}

/**
 *
 */
export enum CurrencyId {
  Ethereum = 'ethereum',
  Binance = 'bsc',
  Pulsechain = 'pulsechain'
}

/**
 *
 */
export type NetworkName =
  | 'mainnet'
  | 'bscMainnet'
  | 'bscTestnet'
  | 'pulsechainMainnet'
  | 'pulsechainTestnet'
  | 'holesky';

/**
 *
 */
export type ColorScheme =
  | 'lightGreen'
  | 'lightPink'
  | 'lightBlue'
  | 'lightGold'
  | 'lightGrey'
  | 'lightOrange'
  | 'lightPurple';

/**
 *
 */
export interface Web3ChainData {
  chainId: Web3ChainId;
  title: string;
  etherscanBaseUrl: string;
  colorScheme: ColorScheme;
  currencyId: CurrencyId;
}

/**
 *
 */
export interface ServiceData {
  chainIds: Web3ChainId[];
}

/**
 *
 */
export enum ServiceId {
  Main,
  Home,
  Hosting,
  Lockup,
  Depositor,
  Host,
  Dashboard,
  Staking,
  Masternodes,
  GamePlatform,
  Daico,
  Marketplace,
  PulsechainDepositor
}

/**
 *
 */
export class UnsupportedWalletError extends Error {}

/**
 *
 */
export class NotMetaMaskError extends Error {}

/**
 *
 */
export class UnsupportedChainIdError extends Error {
  constructor(unsupportedChainId: number, supportedChainIds?: readonly number[]) {
    super(`Unsupported chain id: ${unsupportedChainId}. Supported chain ids are: ${supportedChainIds}.`);

    this.name = UnsupportedChainIdError.name;

    Object.setPrototypeOf(this, UnsupportedChainIdError.prototype);
  }
}

/**
 *
 */
export interface BasicChainInformation {
  urls: string[];
  name: string;
}

/**
 *
 */
export interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

/**
 *
 */
export type ChainConfig = Record<Web3ChainId, BasicChainInformation | ExtendedChainInformation>;

/**
 *
 */
export type ConnectorId =
  | 'coinbase'
  | 'ledger'
  | 'metamask'
  | 'trezor'
  | 'trust-wallet'
  | 'walletconnect'
  | 'okx-wallet';

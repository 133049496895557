import { MediaContext } from '@uikit/media/context';
import { Notification, NotificationContext } from '@uikit/notification/context';
import React, { useState } from 'react';
import { useDetectMedia } from './media/hooks/use-detect-media';

/**
 *
 */
let timeoutId: ReturnType<typeof setTimeout>;

interface UIKitProviderProps {
  children?: React.ReactNode;
}

export function UIKitProvider(props: UIKitProviderProps) {
  const media = useDetectMedia();
  const [notification, setNotification] = useState<Notification | null>(null);

  /**
   *
   * @param state
   * @param timeout
   */
  function pushNotification(state: Notification, timeout?: number): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setNotification(state);

    timeoutId = setTimeout(() => {
      setNotification(null);
    }, timeout ?? 3000);
  }

  return (
    <MediaContext.Provider value={{ media }}>
      <NotificationContext.Provider value={{ notification, pushNotification }}>
        {props.children}
      </NotificationContext.Provider>
    </MediaContext.Provider>
  );
}

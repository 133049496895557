import Add from './add.svg';
import AllEmpty from './all-empty.svg';
import { ArrowDown } from './ArrowDown';
import Back from './back-ui.svg';
import Copy from './copy.svg';
import ForwardDisabled from './forward-disabled.svg';
import Forward from './forward.svg';
import Share from './share.svg';
import Telegram from './telegram.svg';
import Twitter from './twitter.svg';

export { Add, AllEmpty, ArrowDown, Back, Copy, Forward, ForwardDisabled, Share, Telegram, Twitter };

import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  title?: string;
  isFull?: boolean;
  noPaddingTop?: boolean;
  id?: string;
}

const HomeSection = ({ children, title, isFull, id, noPaddingTop }: Props) => {
  return (
    <div className={classNames(styles.section, 'container', noPaddingTop && styles.paddingTop0)} id={id ? id : title}>
      <h2>{noPaddingTop ? '' : title}</h2>

      <div className={isFull ? styles.cardsBlockFull : styles.cardsBlock}>{children}</div>
    </div>
  );
};

export default HomeSection;

import { store } from '@features/../state';
import { isProd } from '@features/env/utils/is-prod';
import { Web3ConnectProvider } from '@features/web3/ConnectProvider';
import { Web3Provider } from '@features/web3/Provider';
import { AccountModalContainer } from '@features/web3/containers/AccountModalContainer';
import { WalletConnectModalContainer } from '@features/web3/containers/WalletConnectModalContainer';
import * as Sentry from '@sentry/node';
import '@styles/_app.scss';
import '@styles/_helpers.scss';
import { UIKitProvider } from '@uikit/Provider';
import { Notification } from '@uikit/notification';
import 'moment/locale/en-gb';
import Head from 'next/head';
import { Provider } from 'react-redux';
import 'react-tippy/dist/tippy.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({ enabled: isProd(), dsn: process.env.NEXT_PUBLIC_SENTRY_DSN });
}

interface Props {
  Component: any;
  pageProps: any;
  err: any;
}

const AbyssFinanceApp = (props: Props) => {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <meta
          name="description"
          content="Abyss Finance provides DeFi/CeFi solutions for crypto related projects and other industries as well."
          key="description"
        />
        <meta property="og:title" content="Abyss Finance" key="og:title" />
        <meta
          property="og:description"
          content="Abyss Finance provides DeFi/CeFi solutions for crypto related projects and other industries as well."
          key="og:description"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://abyss.finance" key="og:url" />
        <meta property="og:image" content="https://abyss.finance/img/thumbnails/af.jpg" key="og:image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@AbyssFinance" />
        <meta name="twitter:image" content="https://abyss.finance/img/thumbnails/af.jpg" key="twitter:image" />
        <meta name="fortmatic-site-verification" content={process.env.NEXT_PUBLIC_FORTMATIC_VERIFICATION} />
        <meta name="apple-mobile-web-app-title" content="Abyss Finance" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="theme-color" content="#2E50CF" />
        <title>Abyss Finance</title>
        <link rel="icon" sizes="32x32" href="/icons/favicon-32x32.png" type="image/png" />
        <link rel="icon" sizes="96x96" href="/icons/favicon-96x96.png" type="image/png" />
        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
        <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png" />
      </Head>

      <Provider store={store}>
        <UIKitProvider>
          <Web3Provider>
            <Web3ConnectProvider>
              <Component {...pageProps} err={props.err} />
              <Notification />
              <WalletConnectModalContainer />
              <AccountModalContainer />
            </Web3ConnectProvider>
          </Web3Provider>
        </UIKitProvider>
      </Provider>
    </>
  );
};

export default AbyssFinanceApp;

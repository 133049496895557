import Lottie, { AnimationItem } from 'lottie-web';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import styles from '../styles.module.scss';

const NavAnimatedIcon = ({ title, path, queryBlock, style, href, snipets, isStaticIcon }: any) => {
  const LottieBlock = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [isClass, setClass] = useState(true);
  const [lottie, setLottie] = useState<AnimationItem | null>(null);

  function scroll() {
    if (queryBlock) {
      const feedback: any = document.getElementById(queryBlock);
      const popup: any = document.getElementById('popupProducts');

      if (feedback) {
        scrollIntoView(feedback, {
          block: 'start',
          behavior: 'smooth'
        } as any);
        popup.setAttribute('style', 'display:none;');

        setTimeout(() => {
          popup.setAttribute('style', 'display:grid;');
        }, 100);
      } else {
        router.push(`/#${queryBlock}`);
      }
    } else if (href) {
      window.open(href, '_blank');
    }
  }

  useEffect(() => {
    let lottie1: any = null;

    if (LottieBlock.current) {
      lottie1 = Lottie.loadAnimation({
        container: LottieBlock.current,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: `${path}`
      });

      lottie1.addEventListener('data_ready', () => {
        setClass(false);
      });

      setLottie(lottie1);
    }

    return () => {
      lottie1?.destroy();
    };
  }, []);

  function handelMouseEnter() {
    if (!isStaticIcon) {
      lottie?.play();
    }
  }

  function handelMouseOut() {
    if (!isStaticIcon) {
      lottie?.stop();
    }
  }

  return (
    <div
      className={styles.popupProductsItemMenu}
      onClick={scroll}
      onMouseEnter={handelMouseEnter}
      onMouseLeave={handelMouseOut}>
      {isStaticIcon ? (
        <img src={path} alt="" style={{ width: '36px', height: '36px' }} />
      ) : (
        <div className={isClass ? style : ''} ref={LottieBlock} style={{ width: '36px', height: '36px' }} />
      )}

      <div className={styles.popupProductsItemMenuText}>
        <p>{title}</p>
        <div className={styles.popupProductsSnipets}>
          {snipets &&
            snipets.map((snip: any, index: number) => (
              <span style={{ color: snip.color }} key={index}>
                {snip.text}
              </span>
            ))}
        </div>
        {/*{description ? <span style={{ color: descriptionColor }}>{description}</span> : null}*/}
      </div>
    </div>
  );
};

export default NavAnimatedIcon;

import { coinbaseConnector } from '@features/web3/connectors/coinbase.connector';
import { metamaskConnector } from '@features/web3/connectors/metamask.connector';
import { trustWalletConnector } from '@features/web3/connectors/trust-wallet.connector';
import { walletconnectConnector } from '@features/web3/connectors/walletconnect.connector';
import { WEB3_CHAIN_DATA } from '@features/web3/constants';
import { useWeb3Context } from '@features/web3/context/web3.context';
import { web3Actions } from '@features/web3/state/actions';
import { TransactionMode, Web3ChainId, Web3ConnectStatus, Web3WalletType } from '@features/web3/types';
import { isCoinbaseInstalled } from '@features/web3/utils/is-coinbase-installed';
import { isMetamaskInstalled } from '@features/web3/utils/is-metamask-installed';
import { isTrustExtensionInstalled } from '@features/web3/utils/is-trust-extension-installed';
import { AppDispatch, AppState } from '@state';
import { useNotification } from '@uikit/notification/hooks/use-notification';
import { destroyCookie, parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { okxWalletConnector } from '../connectors/okx-wallet.connector';
import { isOkxWalletInstalled } from '../utils/is-okx-wallet-installed';

/**
 *
 */
export function useWeb3Connect() {
  const context = useWeb3Context();
  const chainId = context.useSelectedChainId(context.connector);
  const account = context.useSelectedAccount(context.connector);
  const provider = context.useSelectedProvider(context.connector);
  const web3ConnectStatus = useSelector<AppState, Web3ConnectStatus>(state => state.web3.connectStatus);
  const cachedAccount = useSelector<AppState, string | null>(state => state.web3.account);
  const cachedChainId = useSelector<AppState, Web3ChainId | null>(state => state.web3.chainId);
  const cookies = parseCookies();
  const walletType =
    useSelector<AppState, Web3WalletType | null>(state => state.web3.walletType) || cookies.last_connected;
  const dispatch = useDispatch<AppDispatch>();

  const isMetaMask = walletType === Web3WalletType.MetaMask;
  const isCoinbase = walletType === Web3WalletType.Coinbase;
  const isOkxWallet = walletType === Web3WalletType.OkxWallet;
  const isTrustExtension = walletType === Web3WalletType.TrustExtension;
  const isWalletConnect = walletType === Web3WalletType.WalletConnect;
  const isTrustWallet = walletType == Web3WalletType.TrustWallet;
  const shouldConnectMetaMask = cookies.metamask_state !== 'disconnected' && isMetamaskInstalled() && isMetaMask;
  // const lastConnected = cookies.last_connected;
  const shouldConnectCoinbase = cookies.metamask_state !== 'disconnected' && isCoinbaseInstalled() && isCoinbase;
  const shouldConnectOkxWallet = cookies.metamask_state !== 'disconnected' && isOkxWalletInstalled() && isOkxWallet;
  const shouldConnectTrustExtension =
    cookies.metamask_state !== 'disconnected' && isTrustExtensionInstalled() && isTrustExtension;
  const shouldConnectWalletConnect = cookies.wallet_connect_state !== 'disconnected' && isWalletConnect;
  const shouldConnectTrustWallet = cookies.trust_wallet_state !== 'disconnected' && isTrustWallet;
  const isConnectedByWeb3React = account && chainId;
  const isConnectedByState = web3ConnectStatus === Web3ConnectStatus.Connected;
  const [shouldCheckConnection, setShouldCheckConnection] = useState(false);
  const { pushNotification } = useNotification();

  useEffect(() => {
    if (shouldCheckConnection && account == null) {
      setShouldCheckConnection(false);
      destroyCookie(null, 'last_connected');
      dispatch(web3Actions.disconnected());
    }
  }, [shouldCheckConnection, account]);

  useEffect(() => {
    // probably should connect metamask automatically
    if (web3ConnectStatus === Web3ConnectStatus.Unknown) {
      if (shouldConnectMetaMask) {
        const connector = metamaskConnector;

        context.setConnector(connector);
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.MetaMask }));

        connector
          .connectEagerly()
          .then(() => setShouldCheckConnection(true))
          .catch(() => {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          });
      } else if (shouldConnectCoinbase) {
        const connector = coinbaseConnector;

        context.setConnector(connector);
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.Coinbase }));

        connector
          .connectEagerly()
          .then(() => setShouldCheckConnection(true))
          .catch(() => {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          });
      } else if (shouldConnectOkxWallet) {
        const connector = okxWalletConnector;

        context.setConnector(connector);
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.OkxWallet }));

        connector
          .connectEagerly()
          .then(() => setShouldCheckConnection(true))
          .catch(() => {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          });
      } else if (shouldConnectTrustExtension) {
        const connector = trustWalletConnector;

        context.setConnector(connector);
        dispatch(web3Actions.connecting({ walletType: Web3WalletType.TrustExtension }));

        connector
          .connectEagerly()
          .then(() => setShouldCheckConnection(true))
          .catch(() => {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          });
      } else if (shouldConnectWalletConnect) {
        try {
          const walletConnectStateAsJson = localStorage.getItem('wc@2:ethereum_provider:/chainId');

          if (walletConnectStateAsJson === '1') {
            const connector = walletconnectConnector;

            context.setConnector(connector);
            dispatch(web3Actions.connecting({ walletType: Web3WalletType.WalletConnect }));

            connector
              .connectEagerly()
              .then(() => setShouldCheckConnection(true))
              .catch(() => {
                dispatch(web3Actions.disconnected());
                destroyCookie(null, 'last_connected');
              });
          } else {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          }
        } catch {
          dispatch(web3Actions.disconnected());
          destroyCookie(null, 'last_connected');
        }
      } else if (shouldConnectTrustWallet) {
        try {
          const walletConnectStateAsJson = localStorage.getItem('wc@2:ethereum_provider:/chainId');

          if (walletConnectStateAsJson === '1') {
            const connector = walletconnectConnector;

            context.setConnector(connector);
            dispatch(web3Actions.connecting({ walletType: Web3WalletType.WalletConnect }));

            connector
              .connectEagerly()
              .then(() => setShouldCheckConnection(true))
              .catch(() => {
                dispatch(web3Actions.disconnected());
                destroyCookie(null, 'last_connected');
              });
          } else {
            dispatch(web3Actions.disconnected());
            destroyCookie(null, 'last_connected');
          }
        } catch {
          dispatch(web3Actions.disconnected());
          destroyCookie(null, 'last_connected');
        }
      } else {
        dispatch(web3Actions.disconnected());
        destroyCookie(null, 'last_connected');
      }
    }

    // just connected
    if (
      (isMetaMask || isCoinbase || isOkxWallet || isWalletConnect || isTrustWallet || isTrustExtension) &&
      web3ConnectStatus === Web3ConnectStatus.Connecting &&
      isConnectedByWeb3React
    ) {
      if (WEB3_CHAIN_DATA[chainId as Web3ChainId] == null) {
        context.connector.deactivate?.();
        context.connector.resetState();

        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.disconnected());

        pushNotification({
          type: 'failure',
          message: `Please switch network to Mainnet`
        });
      } else {
        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.connected({ account: account!, chainId: chainId! }));

        provider
          ?.getBalance(account)
          .then(balance => dispatch(web3Actions.setBalance({ balance: balance.toString() })));
      }
    }

    // disconnected
    if (isConnectedByState && account == null) {
      dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
      dispatch(web3Actions.disconnected());
    }

    // changed account
    if (
      (isMetaMask || isCoinbase || isOkxWallet || isWalletConnect || isTrustWallet || isTrustExtension) &&
      isConnectedByState &&
      cachedAccount &&
      isConnectedByWeb3React &&
      account !== cachedAccount
    ) {
      dispatch(web3Actions.changedAccount({ account: account! }));
      provider?.getBalance(account).then(balance => dispatch(web3Actions.setBalance({ balance: balance.toString() })));
    }

    // changed chain id
    if (
      (isMetaMask || isCoinbase || isOkxWallet || isWalletConnect || isTrustWallet || isTrustExtension) &&
      isConnectedByState &&
      cachedChainId &&
      isConnectedByWeb3React &&
      chainId !== cachedChainId
    ) {
      if (WEB3_CHAIN_DATA[chainId as Web3ChainId] == null) {
        context.connector.deactivate?.();
        context.connector.resetState();

        dispatch(web3Actions.setTransactionMode({ transactionMode: TransactionMode.Auto }));
        dispatch(web3Actions.disconnected());

        pushNotification({
          type: 'failure',
          message: `Please switch network to Mainnet`
        });
      } else {
        dispatch(web3Actions.changedChainId({ chainId: chainId! }));

        provider
          ?.getBalance(account)
          .then(balance => dispatch(web3Actions.setBalance({ balance: balance.toString() })));
      }
    }
  }, [account, chainId, web3ConnectStatus, cachedAccount, cachedChainId]);
}

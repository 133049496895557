import React from 'react';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  onClick: (value: any) => void;
  value: any;
}

const SelectItem = ({ children, onClick, value }: Props) => {
  function click() {
    onClick(value);
  }

  return (
    <div className={styles.selectItem} onClick={click}>
      {children}
    </div>
  );
};

export default SelectItem;

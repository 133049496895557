import { ModalPortal, OnCloseModal } from '@features/modal/ui/modal';
import { Web3WalletType } from '@features/web3/types';
import { ManualTransactionRow } from '@features/web3/ui/manual-transaction-row';
import { WalletRow } from '@features/web3/ui/wallet-row';
import { isCoinbaseInstalled } from '@features/web3/utils/is-coinbase-installed';
import { isMetamaskInstalled } from '@features/web3/utils/is-metamask-installed';
import { isOkxWalletInstalled } from '@features/web3/utils/is-okx-wallet-installed';
import { isTrustExtensionInstalled } from '@features/web3/utils/is-trust-extension-installed';
import { useState } from 'react';

/**
 *
 */
interface WalletConnectModalProps {
  onClose: OnCloseModal;
  onConnectMetaMask: () => Promise<void>;
  onConnectCoinbase: () => Promise<void>;
  onConnectOkxWallet: () => Promise<void>;
  onConnectTrustExtension: () => Promise<void>;
  onConnectWalletConnect: (walletType?: Web3WalletType.TrustWallet | Web3WalletType.WalletConnect) => Promise<void>;
  onClickLedger: () => void;
  onClickTrezor: () => void;
  onClickManualTransaction: () => void;
  isManualTransactionAllowed: boolean;
  visible?: boolean;
  isDisabledClose?: boolean;
  isMobile: boolean;
  isHidden?: boolean;
}

/**
 *
 * @param props
 * @constructor
 */
export function WalletConnectModal(props: WalletConnectModalProps) {
  const hasMetaMask = isMetamaskInstalled();
  const hasCoinbase = isCoinbaseInstalled();
  const hasOkxWallet = isOkxWalletInstalled();
  const hasTrustExtension = isTrustExtensionInstalled();
  const [selectedWallet, setSelectedWallet] = useState<'trust' | null>(null);

  return props.visible ? (
    <ModalPortal
      title="Connect to a wallet"
      onClose={props.onClose}
      isDisabledClose={props.isDisabledClose}
      isHidden={props.isHidden}>
      {selectedWallet === 'trust' ? (
        <>
          <WalletRow
            walletType={Web3WalletType.TrustExtension}
            onClick={props.onConnectTrustExtension}
            description={
              <>
                {props.isMobile
                  ? hasTrustExtension
                    ? 'Connect using mobile wallet'
                    : 'When installed, open the Trust Wallet app browser'
                  : 'Connect using browser extension'}
              </>
            }
          />
          <WalletRow
            walletType={Web3WalletType.TrustWallet}
            onClick={() => props.onConnectWalletConnect(Web3WalletType.TrustWallet)}
            description={<>Connect using mobile wallet</>}
          />
        </>
      ) : (
        <>
          {props.isMobile ? null : (
            <WalletRow
              walletType={Web3WalletType.Ledger}
              onClick={props.onClickLedger}
              description={<>Connect using hardware wallet</>}
            />
          )}

          {props.isMobile ? null : (
            <WalletRow
              walletType={Web3WalletType.Trezor}
              onClick={props.onClickTrezor}
              description={<>Connect using hardware wallet</>}
            />
          )}

          <WalletRow
            walletType={Web3WalletType.MetaMask}
            onClick={props.onConnectMetaMask}
            description={
              <>
                {props.isMobile
                  ? hasMetaMask
                    ? 'Connect using mobile wallet'
                    : 'When installed, open the MetaMask app browser'
                  : 'Connect using browser extension'}
              </>
            }
          />

          <WalletRow
            walletType={Web3WalletType.Coinbase}
            onClick={props.onConnectCoinbase}
            description={
              <>
                {props.isMobile
                  ? hasCoinbase
                    ? 'Connect using mobile wallet'
                    : 'When installed, open the Coinbase Wallet app browser'
                  : 'Connect using browser extension'}
              </>
            }
          />

          <WalletRow
            walletType={Web3WalletType.TrustWallet}
            onClick={() => setSelectedWallet('trust')}
            description={<>Connect using browser or mobile wallet</>}
          />

          <WalletRow
            walletType={Web3WalletType.OkxWallet}
            onClick={props.onConnectOkxWallet}
            description={
              <>
                {props.isMobile
                  ? hasOkxWallet
                    ? 'Connect using mobile wallet'
                    : 'When installed, open the OKX Wallet app browser'
                  : 'Connect using browser extension'}
              </>
            }
          />

          <WalletRow
            walletType={Web3WalletType.WalletConnect}
            onClick={() => props.onConnectWalletConnect(Web3WalletType.WalletConnect)}
            description={<>Connect using compatible wallet</>}
          />

          {props.isManualTransactionAllowed ? <ManualTransactionRow onClick={props.onClickManualTransaction} /> : null}

          {props.isMobile ? (
            <>
              <WalletRow walletType={Web3WalletType.Ledger} description={<>Connect using hardware wallet</>} />

              <WalletRow walletType={Web3WalletType.Trezor} description={<>Connect using hardware wallet</>} />
            </>
          ) : null}
        </>
      )}
    </ModalPortal>
  ) : null;
}

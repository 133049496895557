import { ModalPortal, OnCloseModal } from '@features/modal/ui/modal';
import { WEB3_WALLET_DATA } from '@features/web3/constants';
import { useWeb3 } from '@features/web3/hooks/use-web3';
import { Web3WalletType } from '@features/web3/types';
import { makeAddressUrl } from '@features/web3/utils/make-address-url';
import { makeShortAddress } from '@features/web3/utils/make-short-address';
import { toNetworkName } from '@features/web3/utils/to-network-name';
import { Button } from '@uikit/button';
import { CopyLink } from '@uikit/copy-link';
import { EtherscanLink } from '@uikit/etherscan-link';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import styles from './styles.module.scss';

interface AccountModalProps {
  onClose: OnCloseModal;
  onDisconnect: () => void;
  visible?: boolean;
  accountId?: string | null;
}

export function AccountModal(props: AccountModalProps) {
  const { onClose, accountId, onDisconnect } = props;
  const { chainId, walletType } = useWeb3();
  const networkName = chainId && toNetworkName(chainId);

  const linkTitle = ['pulsechainMainnet', 'pulsechainTestnet'].includes(networkName!)
    ? 'View on PulseChain Explorer'
    : ['bscMainnet', 'bscTestnet'].includes(networkName!)
    ? 'View on BscScan'
    : 'View on Etherscan';

  useEffect(() => {
    if (!accountId) {
      props.onClose();
    }
  }, [accountId]);

  /**
   *
   */
  const handleDisconnect = useCallback(() => {
    onClose();
    onDisconnect();
  }, [onDisconnect, onClose]);

  return props.visible ? (
    <ModalPortal title="Account" onClose={props.onClose}>
      <div className="flex-column">
        <div className={classNames(styles.header, 'flex-row flex-space-between')}>
          {walletType === Web3WalletType.WalletConnect ? (
            <span className={styles.connected}>Connected through {WEB3_WALLET_DATA[walletType!]?.title}</span>
          ) : (
            <span className={styles.connected}>Connected with {WEB3_WALLET_DATA[walletType!]?.title}</span>
          )}

          <Button type="secondary" onClick={handleDisconnect} className={styles.disconnect}>
            Disconnect
          </Button>
        </div>

        <div className={classNames(styles.address, 'flex-row flex-start')}>
          {accountId ? makeShortAddress(accountId, 3) : null}
        </div>

        <div className={classNames(styles.buttons, 'flex-row flex-start')}>
          {accountId ? <CopyLink title="Copy Address" value={accountId} /> : null}
          {chainId && accountId ? <EtherscanLink title={linkTitle} value={makeAddressUrl(chainId, accountId)} /> : null}
        </div>
      </div>
    </ModalPortal>
  ) : (
    <div />
  );
}

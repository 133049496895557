import { Media } from '@uikit/media/context';
import { useEffect, useState } from 'react';

export function useDetectMedia() {
  const [media, setMedia] = useState<Media>(Media.Unknown);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1015 && media !== Media.Mobile) {
        setMedia(Media.Mobile);
      } else if (media !== Media.Desktop) {
        setMedia(Media.Desktop);
      } else {
        setMedia(Media.Unknown);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return media;
}

import { ModalPortal, OnCloseModal } from '@features/modal/ui/modal';
import { WEB3_CHAIN_DATA } from '@features/web3/constants';
import { Web3ChainId } from '@features/web3/types';
import Button from '@uikit/button';
import Select from '@uikit/select';
import { useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  onClose: OnCloseModal;
  visible?: boolean;
  shouldShowLedgerDerivation: boolean;
  onConnectLedger: (chainId: Web3ChainId, derivation: Derivation) => Promise<void>;
  onConnectTrezor: (chainId: Web3ChainId) => Promise<void>;
  isDerivationSelectOpenedByDefault: boolean;
}

type DerivationType = 'new' | 'old' | 'metamask';

interface Derivation {
  type: DerivationType;
  derivation: string;
  name: string;
}

const DERIVATIONS: Record<DerivationType, Derivation> = {
  new: {
    type: 'new',
    derivation: `44'/60'/0'/0`,
    name: `m/44'/60' - Ledger Live`
  },
  old: {
    type: 'old',
    derivation: `44'/60'/0'`,
    name: `m/44'/60'/0' - MyEtherWallet`
  },
  metamask: {
    type: 'metamask',
    derivation: `44'/60'/0'/0`,
    name: `m/44'/60'/0'/0 - MetaMask, Trezor`
  }
};

export function SelectNetworkModal(props: Props) {
  const [network, setNetwork] = useState<Web3ChainId>(Web3ChainId.Mainnet);
  const [derivation, setDerivation] = useState<Derivation>(DERIVATIONS.new);

  /**
   *
   * @param item
   */
  function handleSelectNetwork(item: string) {
    const chainData = Object.values(WEB3_CHAIN_DATA).find(chainData => chainData.title === item);

    if (chainData) {
      setNetwork(chainData.chainId);
    }
  }

  /**
   *
   * @param item
   */
  function handleSelectDerivation(item: string) {
    const derivation = Object.values(DERIVATIONS).find(derivation => derivation.name === item);

    if (derivation) {
      setDerivation(derivation);
    }
  }

  /**
   *
   */
  async function handleSubmit() {
    if (props.shouldShowLedgerDerivation) {
      await props.onConnectLedger(network, derivation);
    } else {
      await props.onConnectTrezor(network);
    }
  }

  return props.visible ? (
    <ModalPortal title="Choose the network" onClose={props.onClose} style={{ overflow: 'visible' }}>
      <Select
        className={styles.select}
        label="Choose the Ethereum network"
        value={WEB3_CHAIN_DATA[network].title}
        onSelect={handleSelectNetwork}
        items={[WEB3_CHAIN_DATA[Web3ChainId.Mainnet].title, WEB3_CHAIN_DATA[Web3ChainId.Holesky].title]}
      />

      {props.shouldShowLedgerDerivation ? (
        <Select
          className={styles.select}
          label="Select derivation path"
          value={derivation.name}
          onSelect={handleSelectDerivation}
          items={Object.values(DERIVATIONS).map(derivation => derivation.name)}
          isOpenedByDefault={props.isDerivationSelectOpenedByDefault}
        />
      ) : null}

      <Button type="primary" onClick={handleSubmit} className={styles.button}>
        NEXT
      </Button>
    </ModalPortal>
  ) : null;
}

import classNames from 'classnames';
import styles from './styles.module.scss';

function Header() {
  return (
    <div className={classNames(styles.header, 'container')}>
      <h1>
        Blockchain <br />
        Financial Solutions <br />
      </h1>
      <img src="/img/top.png" alt="" className="img-top" />
    </div>
  );
}

export default Header;

import { WEB3_WALLET_DATA } from '@features/web3/constants';
import { Web3WalletType } from '@features/web3/types';
import { isCoinbaseInstalled } from '@features/web3/utils/is-coinbase-installed';
import { isMetamaskInstalled } from '@features/web3/utils/is-metamask-installed';
import { isOkxWalletInstalled } from '@features/web3/utils/is-okx-wallet-installed';
import { isTrustExtensionInstalled } from '@features/web3/utils/is-trust-extension-installed';
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

/**
 *
 */
interface Props {
  walletType: Web3WalletType;
  onClick?: () => void;
  description: React.ReactNode;
}

/**
 *
 * @param props
 * @constructor
 */
export function WalletRow(props: Props) {
  const { Logo } = WEB3_WALLET_DATA[props.walletType];
  const needInstallMetaMask = props.walletType === Web3WalletType.MetaMask && !isMetamaskInstalled();
  const needInstallCoinbase = props.walletType === Web3WalletType.Coinbase && !isCoinbaseInstalled();
  const needInstallOkxWallet = props.walletType === Web3WalletType.OkxWallet && !isOkxWalletInstalled();
  const needInstallTrustExtension = props.walletType === Web3WalletType.TrustExtension && !isTrustExtensionInstalled();

  /**
   *
   */
  function handleClick() {
    if (needInstallTrustExtension) {
      window.open('https://trustwallet.com/browser-extension', '_blank');
    } else if (needInstallCoinbase) {
      window.open('https://coinbase-wallet.onelink.me/q5Sx/fdb9b250', '_blank');
    } else if (needInstallMetaMask) {
      window.open('https://metamask.io/download.html', '_blank');
    } else if (needInstallOkxWallet) {
      window.open('https://www.okx.com/web3', '_blank');
    } else {
      props.onClick?.();
    }
  }

  return (
    <div
      className={classNames(styles.row, 'flex-row flex-start')}
      onClick={handleClick}
      style={{ opacity: props.onClick ? 1 : 0.25 }}>
      <div className={classNames(styles.logo, 'flex-row flex-center')}>{Logo ? <Logo /> : null}</div>

      <div className={classNames(styles.content, 'flex-column')}>
        <span className={styles.title}>
          {needInstallMetaMask ? (
            'Install MetaMask'
          ) : needInstallCoinbase ? (
            'Install Coinbase Wallet'
          ) : needInstallTrustExtension ? (
            'Install Trust Wallet'
          ) : needInstallOkxWallet ? (
            'Install OKX Wallet'
          ) : props.walletType === Web3WalletType.WalletConnect ? (
            <>
              WalletConnect <span>2.0</span>
            </>
          ) : (
            WEB3_WALLET_DATA[props.walletType].title
          )}
        </span>

        <span className={styles.description}>{props.description}</span>
      </div>
    </div>
  );
}

import classNames from 'classnames';
import styles from '../wallet-row/styles.module.scss';
import ManualSvg from './assets/manual.svg';

/**
 *
 */
interface Props {
  onClick: () => void;
}

/**
 *
 * @param props
 * @constructor
 */
export function ManualTransactionRow(props: Props) {
  /**
   *
   */
  function handleClick() {
    props.onClick();
  }

  return (
    <div className={classNames(styles.row, 'flex-row flex-start')} onClick={handleClick}>
      <div className={classNames(styles.logo, 'flex-row flex-center')}>
        <ManualSvg />
      </div>

      <div className={classNames(styles.content, 'flex-column')}>
        <span className={styles.title}>Manual transaction</span>
      </div>
    </div>
  );
}

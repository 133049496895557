import { useContext } from 'react';
import { NotificationContext, NotificationValue } from '../context';

/**
 *
 */
export function useNotification() {
  const context = useContext<NotificationValue>(NotificationContext);

  return {
    type: context.notification?.type ?? null,
    message: context.notification?.message ?? null,
    pushNotification: context.pushNotification
  };
}

import { Web3ReactHooks } from '@web3-react/core/dist/hooks';
import { Connector } from '@web3-react/types';
import { coinbaseConnector, coinbaseConnectorHooks } from './coinbase.connector';
import { ledgerConnector, ledgerConnectorHooks } from './ledger.connector';
import { metamaskConnector, metamaskConnectorHooks } from './metamask.connector';
import { okxWalletConnector, okxWalletConnectorHooks } from './okx-wallet.connector';
import { trezorConnector, trezorConnectorHooks } from './trezor.connector';
import { trustWalletConnector, trustWalletConnectorHooks } from './trust-wallet.connector';
import { walletconnectConnector, walletconnectConnectorHooks } from './walletconnect.connector';

export const connectors: [Connector, Web3ReactHooks][] = [
  [ledgerConnector, ledgerConnectorHooks],
  [trezorConnector, trezorConnectorHooks],
  [coinbaseConnector, coinbaseConnectorHooks],
  [metamaskConnector, metamaskConnectorHooks],
  [trustWalletConnector, trustWalletConnectorHooks],
  [walletconnectConnector, walletconnectConnectorHooks],
  [okxWalletConnector, okxWalletConnectorHooks]
  // ...Object.values(walletconnectConnectors).map(
  //   connector => [connector[0], connector[1]] as [Connector, Web3ReactHooks]
  // )
];

export const visibleConnectors: [Connector, Web3ReactHooks][] = [
  [ledgerConnector, ledgerConnectorHooks],
  [trezorConnector, trezorConnectorHooks],
  [coinbaseConnector, coinbaseConnectorHooks],
  [metamaskConnector, metamaskConnectorHooks],
  [trustWalletConnector, trustWalletConnectorHooks],
  [walletconnectConnector, walletconnectConnectorHooks],
  [okxWalletConnector, okxWalletConnectorHooks]
];

import { SERVICE_DATA } from '@features/web3/constants';
import { ServiceId, Web3ChainId } from '@features/web3/types';
import { Ethereum1NetworkName, Ethereum2NetworkName, SupportedContractType } from './types';

/**
 *
 */
export const ETHEREUM_1_NETWORK_NAMES: Record<Ethereum1NetworkName, Ethereum1NetworkName> = {
  mainnet: 'mainnet'
};

/**
 *
 */
export const ETHEREUM_2_NETWORK_NAMES: Record<Ethereum2NetworkName, Ethereum2NetworkName> = {
  mainnet: 'mainnet',
  prater: 'prater'
};

/**
 *
 */
export const SUPPORTED_CONTRACT_TYPES: Record<SupportedContractType, SupportedContractType> = {
  safe14: 'safe14',
  safe28: 'safe28',
  safe90: 'safe90'
};

/**
 *
 */
export const USD_LOCK_AMOUNTS: Record<SupportedContractType, { firstNode: number; additionalNode: number }> = {
  safe14: { firstNode: 300, additionalNode: 450 },
  safe28: { firstNode: 200, additionalNode: 300 },
  safe90: { firstNode: 100, additionalNode: 150 }
};

/**
 *
 */
export const FULL_INSURANCE_MULTIPLIER = 5;

/**
 *
 */
export const ABYSS_TOKEN_ADDRESSES: Record<Ethereum1NetworkName, string> = {
  mainnet: process.env.NEXT_PUBLIC_ABYSS_TOKEN_ADDRESS_MAINNET!
};

/**
 *
 */
export const ABYSS_ETH_POOL_ADDRESSES: Record<Ethereum1NetworkName, string> = {
  mainnet: process.env.NEXT_PUBLIC_ABYSS_POOL_ADDRESS_MAINNET!
};

/**
 *
 */
export const ABYSS_TOKEN_DECIMALS = 18;

/**
 *
 */
export const MAX_NODE_AMOUNT = 100;

/**
 *
 */
export const HOST_CHAIN_IDS: Web3ChainId[] = SERVICE_DATA[ServiceId.Host].chainIds;

import { NetworkName, SafeContractType } from '@features/lockup/types';
import { Web3ChainId } from '@features/web3/types';

/**
 *
 */
export const SAFE_CONTRACT_TYPES: Record<SafeContractType, SafeContractType> = {
  safe1: 'safe1',
  safe3: 'safe3',
  safe7: 'safe7',
  safe14: 'safe14',
  safe21: 'safe21',
  safe28: 'safe28',
  safe90: 'safe90',
  safe180: 'safe180',
  safe365: 'safe365'
};

/**
 *
 */
export const NETWORK_NAMES: Record<NetworkName, NetworkName> = {
  mainnet: 'mainnet',
  bscMainnet: 'bscMainnet',
  bscTestnet: 'bscTestnet',
  pulsechainMainnet: 'pulsechainMainnet',
  pulsechainTestnet: 'pulsechainTestnet',
  holesky: 'holesky'
};

/**
 *
 */
export const DEFAULT_NETWORK_NAME: NetworkName = 'mainnet';

/**
 *
 */
export const LOCKUP_ADDRESSES: Record<NetworkName, Record<SafeContractType, string> & { lockup: string }> = {
  mainnet: {
    lockup: process.env.NEXT_PUBLIC_LOCKUP_ADDRESS_MAINNET! as string,
    safe1: process.env.NEXT_PUBLIC_SAFE_1_ADDRESS_MAINNET! as string,
    safe3: process.env.NEXT_PUBLIC_SAFE_3_ADDRESS_MAINNET! as string,
    safe7: process.env.NEXT_PUBLIC_SAFE_7_ADDRESS_MAINNET! as string,
    safe14: process.env.NEXT_PUBLIC_SAFE_14_ADDRESS_MAINNET! as string,
    safe21: process.env.NEXT_PUBLIC_SAFE_21_ADDRESS_MAINNET! as string,
    safe28: process.env.NEXT_PUBLIC_SAFE_28_ADDRESS_MAINNET! as string,
    safe90: process.env.NEXT_PUBLIC_SAFE_90_ADDRESS_MAINNET! as string,
    safe180: process.env.NEXT_PUBLIC_SAFE_180_ADDRESS_MAINNET! as string,
    safe365: process.env.NEXT_PUBLIC_SAFE_365_ADDRESS_MAINNET! as string
  },
  bscMainnet: {
    lockup: process.env.NEXT_PUBLIC_LOCKUP_ADDRESS_BSC_MAINNET! as string,
    safe1: process.env.NEXT_PUBLIC_SAFE_1_ADDRESS_BSC_MAINNET! as string,
    safe3: process.env.NEXT_PUBLIC_SAFE_3_ADDRESS_BSC_MAINNET! as string,
    safe7: process.env.NEXT_PUBLIC_SAFE_7_ADDRESS_BSC_MAINNET! as string,
    safe14: process.env.NEXT_PUBLIC_SAFE_14_ADDRESS_BSC_MAINNET! as string,
    safe21: process.env.NEXT_PUBLIC_SAFE_21_ADDRESS_BSC_MAINNET! as string,
    safe28: process.env.NEXT_PUBLIC_SAFE_28_ADDRESS_BSC_MAINNET! as string,
    safe90: process.env.NEXT_PUBLIC_SAFE_90_ADDRESS_BSC_MAINNET! as string,
    safe180: process.env.NEXT_PUBLIC_SAFE_180_ADDRESS_BSC_MAINNET! as string,
    safe365: process.env.NEXT_PUBLIC_SAFE_365_ADDRESS_BSC_MAINNET! as string
  },
  bscTestnet: {
    lockup: process.env.NEXT_PUBLIC_LOCKUP_ADDRESS_BSC_TESTNET! as string,
    safe1: process.env.NEXT_PUBLIC_SAFE_1_ADDRESS_BSC_TESTNET! as string,
    safe3: process.env.NEXT_PUBLIC_SAFE_3_ADDRESS_BSC_TESTNET! as string,
    safe7: process.env.NEXT_PUBLIC_SAFE_7_ADDRESS_BSC_TESTNET! as string,
    safe14: process.env.NEXT_PUBLIC_SAFE_14_ADDRESS_BSC_TESTNET! as string,
    safe21: process.env.NEXT_PUBLIC_SAFE_21_ADDRESS_BSC_TESTNET! as string,
    safe28: process.env.NEXT_PUBLIC_SAFE_28_ADDRESS_BSC_TESTNET! as string,
    safe90: process.env.NEXT_PUBLIC_SAFE_90_ADDRESS_BSC_TESTNET! as string,
    safe180: process.env.NEXT_PUBLIC_SAFE_180_ADDRESS_BSC_TESTNET! as string,
    safe365: process.env.NEXT_PUBLIC_SAFE_365_ADDRESS_BSC_TESTNET! as string
  },
  holesky: {
    lockup: '',
    safe1: '',
    safe3: '',
    safe7: '',
    safe14: '',
    safe21: '',
    safe28: '',
    safe90: '',
    safe180: '',
    safe365: ''
  },
  pulsechainMainnet: {
    lockup: '',
    safe1: '',
    safe3: '',
    safe7: '',
    safe14: '',
    safe21: '',
    safe28: '',
    safe90: '',
    safe180: '',
    safe365: ''
  },
  pulsechainTestnet: {
    lockup: '',
    safe1: '',
    safe3: '',
    safe7: '',
    safe14: '',
    safe21: '',
    safe28: '',
    safe90: '',
    safe180: '',
    safe365: ''
  }
};

/**
 *
 */
export const LOCKUP_CHAIN_IDS: Web3ChainId[] = [Web3ChainId.Mainnet, Web3ChainId.BscMainnet, Web3ChainId.BscTestnet];

import { NetworkName, Result, SafeContractType } from '@features/lockup/types';
import { Web3ChainId } from '@features/web3/types';
import { makeTokenLogoUrl } from '@features/web3/utils/make-token-logo-url';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

/**
 *
 */
const setNetwork = createAction<{ networkName: NetworkName }>('lockup/setNetworkName');

/**
 *
 */
const setTokenLogos = createAction<{ tokenLogos: Record<string, string | null>; networkName: NetworkName }>(
  'lockup/setTokenLogos'
);

/**
 *
 */
const fetchResult = createAsyncThunk<{ result: Result }, { networkName: NetworkName }>(
  'lockup/fetchResult',
  async (payload, thunk) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/v1/lockup/tokens/${payload.networkName}`);
    const result: Result = await response.json();
    const tokenLogos: Record<string, string | null> = {};

    await Promise.all(
      Object.values(result.tokens).map(async token => {
        const [tokenLogoUrl, token0LogoUrl, token1LogoUrl] = await Promise.all([
          makeTokenLogoUrl(token.token.address),
          token.token0 ? makeTokenLogoUrl(token.token0.address) : Promise.resolve(null),
          token.token1 ? makeTokenLogoUrl(token.token1.address) : Promise.resolve(null)
        ]);

        tokenLogos[token.token.address] = tokenLogoUrl;

        if (token.token0) {
          tokenLogos[token.token0.address] = token0LogoUrl;
        }

        if (token.token1) {
          tokenLogos[token.token1.address] = token1LogoUrl;
        }

        thunk.dispatch(setTokenLogos({ tokenLogos, networkName: payload.networkName }));
      })
    );

    return { result };
  }
);

/**
 *
 */
const setRequestTokensData = createAction<{
  contractAddress: string;
  tokenAddress: string;
  decimals: number;
  balance: number;
}>('lockup/setRequestTokenData');

/**
 *
 */
const clearLockModalState = createAction('lockup/clearLockModalState');

/**
 *
 */
const setLockModalExpectedTokenAddress = createAction<{ tokenAddress: string }>(
  'lockup/setLockModalExpectedTokenAddress'
);

/**
 *
 */
const setLockModalExpectedChainId = createAction<{ chainId: Web3ChainId }>('lockup/setLockModalExpectedChainId');

/**
 *
 */
const setLockModalUnlockPeriod = createAction<{ unlockPeriod: SafeContractType }>('lockup/setLockModalUnlockPeriod');

/**
 *
 */
const setLockModalAmount = createAction<{ amount: string }>('lockup/setLockModalAmount');

/**
 *
 */
export const lockupActions = {
  setNetwork,
  setTokenLogos,
  fetchResult,
  setRequestTokensData,
  clearLockModalState,
  setLockModalExpectedTokenAddress,
  setLockModalExpectedChainId,
  setLockModalUnlockPeriod,
  setLockModalAmount
};

import classNames from 'classnames';
import ReactDOM from 'react-dom';
import CheckSvg from './assets/check.svg';
import ErrorSvg from './assets/error.svg';
import { useNotification } from './hooks/use-notification';
import styles from './styles.module.scss';

const notificationDom = typeof window !== 'undefined' ? document.getElementById('notification') : null;

export function Notification() {
  const { message, type } = useNotification();

  if (type && message && notificationDom) {
    return ReactDOM.createPortal(
      <div className={classNames(styles.notification, styles[type], 'flex-row flex-center')}>
        {type === 'success' ? <CheckSvg className={styles.img} /> : <ErrorSvg className={styles.img} />}
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: message }} />
      </div>,
      notificationDom
    );
  }

  return <></>;
}

import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { initializeConnector } from '@web3-react/core';
import { CHAINS } from '../chains';
import { Web3ChainId } from '../types';

export const [coinbaseConnector, coinbaseConnectorHooks] = initializeConnector<CoinbaseWallet>(actions => {
  return new CoinbaseWallet({
    actions,
    options: {
      url: CHAINS[Web3ChainId.Mainnet].urls[0],
      appName: process.env.NEXT_PUBLIC_COINBASE_APP_NAME!
    }
  });
});

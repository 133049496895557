import { DEFAULT_NETWORK_NAME } from '@features/lockup/constants';
import { lockupActions } from '@features/lockup/state/actions';
import { NetworkName, Result, SafeContractType } from '@features/lockup/types';
import { Web3ChainId } from '@features/web3/types';
import { createReducer } from '@reduxjs/toolkit';

/**
 *
 */
export interface LockModalState {
  expectedTokenAddress: string | null;
  expectedChainId: Web3ChainId | null;
  isTokenListLoading: boolean;
  unlockPeriod: SafeContractType;
  amount: string | null;
}

/**
 *
 */
export interface LockupState {
  networkName: NetworkName;
  result: Record<NetworkName, Result | null>;
  isLoading: Record<NetworkName, boolean>;
  isLoaded: Record<NetworkName, boolean>;
  tokenLogos: Record<NetworkName, Record<string, string | null>>;
  request: {
    contractAddress: string;
    tokenAddress: string;
    decimals: number;
    balance: number;
  };
  lockModal: LockModalState;
}

/**
 *
 */
const INITIAL_LOCK_MODAL_STATE: LockModalState = {
  expectedTokenAddress: null,
  expectedChainId: null,
  isTokenListLoading: false,
  unlockPeriod: 'safe1',
  amount: null
};

/**
 *
 */
const INITIAL_STATE: LockupState = {
  networkName: DEFAULT_NETWORK_NAME,
  result: {
    mainnet: null,
    bscMainnet: null,
    bscTestnet: null,
    pulsechainMainnet: null,
    pulsechainTestnet: null,
    holesky: null
  },
  isLoading: {
    mainnet: true,
    bscMainnet: true,
    bscTestnet: true,
    pulsechainMainnet: true,
    pulsechainTestnet: true,
    holesky: true
  },
  isLoaded: {
    mainnet: false,
    bscMainnet: false,
    bscTestnet: false,
    pulsechainMainnet: false,
    pulsechainTestnet: false,
    holesky: false
  },
  tokenLogos: {
    mainnet: {},
    bscMainnet: {},
    bscTestnet: {},
    pulsechainMainnet: {},
    pulsechainTestnet: {},
    holesky: {}
  },
  request: {
    contractAddress: '',
    tokenAddress: '',
    decimals: 1,
    balance: 0
  },
  lockModal: INITIAL_LOCK_MODAL_STATE
};

/**
 *
 */
export const lockupReducer = createReducer<LockupState>(INITIAL_STATE, builder => {
  builder
    .addCase(lockupActions.setNetwork, (state, action) => {
      state.networkName = action.payload.networkName;
    })
    .addCase(lockupActions.setTokenLogos, (state, action) => {
      state.tokenLogos[action.payload.networkName] = {
        ...state.tokenLogos[action.payload.networkName],
        ...action.payload.tokenLogos
      };
    })
    .addCase(lockupActions.fetchResult.pending, (state, action) => {
      state.isLoading[action.meta.arg.networkName] = true;
    })
    .addCase(lockupActions.fetchResult.fulfilled, (state, action) => {
      state.isLoading[action.meta.arg.networkName] = false;
      state.isLoaded[action.meta.arg.networkName] = true;
      state.result[action.meta.arg.networkName] = action.payload.result;
    })
    .addCase(lockupActions.fetchResult.rejected, (state, action) => {
      state.isLoading[action.meta.arg.networkName] = false;
    })
    .addCase(lockupActions.setRequestTokensData, (state, action) => {
      state.request = action.payload;
    })
    .addCase(lockupActions.clearLockModalState, state => {
      state.lockModal = INITIAL_LOCK_MODAL_STATE;
    })
    .addCase(lockupActions.setLockModalExpectedTokenAddress, (state, action) => {
      state.lockModal.expectedTokenAddress = action.payload.tokenAddress;
    })
    .addCase(lockupActions.setLockModalExpectedChainId, (state, action) => {
      state.lockModal.expectedChainId = action.payload.chainId;
    })
    .addCase(lockupActions.setLockModalUnlockPeriod, (state, action) => {
      state.lockModal.unlockPeriod = action.payload.unlockPeriod;
    })
    .addCase(lockupActions.setLockModalAmount, (state, action) => {
      state.lockModal.amount = action.payload.amount;
    });
});

import { createAction } from '@reduxjs/toolkit';

/**
 *
 */
const openWalletConnectModal = createAction<{ isManualTransactionAllowed?: boolean }>('modal/openWalletConnect');

/**
 *
 */
const openAccountModal = createAction('modal/openAccount');

/**
 *
 */
const openLockTokens = createAction('modal/openLockTokens');

/**
 *
 */
const openRequestTokens = createAction('modal/openRequestTokens');

/**
 *
 */
const close = createAction('modal/close');

/**
 *
 */
export const modalActions = {
  openWalletConnectModal,
  openAccountModal,
  openLockTokens,
  openRequestTokens,
  close
};

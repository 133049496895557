import React, { forwardRef } from 'react';
import * as IconsSVG from './svg';

export type IconsList = keyof typeof IconsSVG;

export interface IconProps {
  src: IconsList;
  onClick?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
  className?: string;
}

export const Icon = forwardRef<HTMLOrSVGElement, IconProps>(function Icon(props, ref) {
  const Element = IconsSVG[props.src];

  return <Element ref={ref} onClick={props.onClick} className={props.className} />;
});

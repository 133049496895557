import classnames from 'classnames/bind';
import React, { forwardRef } from 'react';
import { Icon, IconsList } from '../icons';
import styles from './styles.module.scss';

const cx = classnames.bind(styles);

export interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  icon?: IconsList;
  type?: 'primary' | 'secondary' | 'white' | 'green';
  variant?: 'text' | 'contained' | 'icon';
  styles?: React.CSSProperties;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { type = 'primary', icon, variant = 'contained' } = props;
  const buttonClassList = cx(styles.wrapper, styles[type], props.className, {
    textContained: variant === 'text',
    iconContained: variant === 'icon'
  });

  const iconClassList = cx(styles.icon, {
    onlyIcon: !props.children
  });

  return (
    <button
      ref={ref}
      className={buttonClassList}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.styles}>
      {icon && (
        <div className={iconClassList}>
          <Icon src={icon} />
        </div>
      )}
      {props.children}
    </button>
  );
});

export default Button;

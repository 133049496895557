import { BigNumber } from '@features/format/utils/BigNumber';
import { formatNumber } from '@features/format/utils/format-number';

/**
 *
 * @param balance
 * @param currencySymbol
 */
export function formatWalletBalance(balance: string, currencySymbol: 'ETH' | 'BNB' | 'PLS'): string {
  return formatNumber(new BigNumber(balance).div(1e18).toNumber(), 8) + ` ${currencySymbol}`;
}

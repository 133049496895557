import styles from './styles.module.scss';

interface Props {
  onAction?: () => void;
  name: string;
  iconUrl?: string;
  additionalText?: string;
}

const DropDownDepositorItem = (props: Props) => {
  return (
    <div
      className={styles.subMenuItem}
      style={{ height: props.additionalText && '60px', paddingBottom: props.additionalText && '20px' }}
      onClick={props.onAction}>
      {props.iconUrl ? <img src={props.iconUrl} alt="" /> : null}
      <p style={{ marginTop: props.additionalText ? '20px' : '0px' }}>
        {props.name} <br /> {props.additionalText ? <span>{props.additionalText}</span> : null}
      </p>
    </div>
  );
};

export default DropDownDepositorItem;

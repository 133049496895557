import { useOnClickOutside } from '@uikit/hooks/use-on-click-outside';
import SelectItem from '@uikit/select/item';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  label: string;
  value: string;
  className?: string | null;
  onSelect: (value: any) => void;
  items: any;
  isOpenedByDefault?: boolean;
}

const Select = ({ label, value, className, onSelect, items, isOpenedByDefault }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenedByDefault ?? false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  function select(value: any) {
    setIsOpen(false);
    onSelect(value);
  }

  return (
    <div className={classNames(styles.select, className)}>
      <span className={styles.label}>{label}</span>

      <div className={styles.selectContent} ref={ref}>
        <div className={styles.selectValue} onClick={() => setIsOpen(!isOpen)}>
          {value}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            className={classNames(isOpen ? styles.rotateIcon : null)}>
            <g fill="none" fillRule="evenodd">
              <g fill="#222222" fillRule="nonzero">
                <g>
                  <path
                    d="M97.342 19.16c.063.062.098.147.098.236s-.035.174-.098.237l-.707.706c-.06.065-.145.101-.233.101-.089 0-.173-.036-.234-.1l-4.126-4.127c-.094-.094-.147-.221-.147-.354v-.173c0-.133.053-.26.147-.353l4.126-4.127c.06-.064.145-.101.234-.101.088 0 .173.037.233.101l.707.707c.063.062.098.147.098.236 0 .09-.035.174-.098.237l-3.387 3.387 3.387 3.386z"
                    transform="translate(-440 -1091) translate(350 1078) rotate(-90 94.668 15.773)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        {isOpen ? (
          <div className={styles.selectItems}>
            {items.map((item: any) => (
              <SelectItem value={item} onClick={value => select(value)} key={item}>
                {item}
              </SelectItem>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Select;

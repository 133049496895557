import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  icon?: string;
  onClick?: React.MouseEventHandler;
  text: string;
  disabled?: boolean;
}

function DropDownItem({ icon, onClick = () => {}, text, disabled = false }: Props) {
  return (
    <div className={classNames(styles.dropDownItem, disabled ? styles.dropDownItemDisabled : null)} onClick={onClick}>
      {icon ? (
        <div className={styles.dropDownItemIconBlock}>
          <img src={icon} alt="" />
        </div>
      ) : null}

      <p>{text}</p>
    </div>
  );
}

export default DropDownItem;

import classNames from 'classnames';
import { CSSProperties } from 'react';
import LinkSvg from './assets/link.svg';
import styles from './styles.module.scss';

interface Props {
  title?: string;
  value: string;
  style?: CSSProperties;
}

export function EtherscanLink(props: Props) {
  return (
    <a
      href={props.value}
      target="_blank"
      className={classNames(styles.link, 'flex-row flex-start')}
      style={props.style}
      onClick={e => e.stopPropagation()}>
      <LinkSvg style={{ marginRight: 10 }} />
      {props.title}
    </a>
  );
}

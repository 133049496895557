import SmartContractInfoModal from '@features/web3/ui/smart-contract-info-modal';
import { Button } from '@uikit/button';
import DropDownMobileDepositor from '@uikit/dropdown-mobile-depositor';
import classNames from 'classnames';
import Lottie, { AnimationItem } from 'lottie-web';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tippy';
import LogoMixBytes from '../../../../../public/img/cards/LogoMixBytes.svg';
import NewAlchemySvg from '../../../../../public/img/cards/new-alchemy.svg';
import styles from './styles.module.scss';

interface Props {
  data: any;
  path?: any;
  style?: any;
}

const HomeCard = ({ data, path, style }: Props) => {
  const router = useRouter();
  const LottieBlock = useRef<HTMLDivElement>(null);
  const [isClass, setClass] = useState(true);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [isPulsechainDepositorModalOpened, setIsPulsechainDepositorModalOpened] = useState(false);
  const [lottie, setLottie] = useState<AnimationItem | null>(null);

  useEffect(() => {
    let lottie1: any = null;

    if (LottieBlock.current) {
      lottie1 = Lottie.loadAnimation({
        container: LottieBlock.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: `${path}`
      });

      lottie1.addEventListener('data_ready', () => {
        setClass(false);
      });

      setLottie(lottie1);
    }

    return () => {
      lottie1?.destroy();
    };
  }, []);

  function handleMouseEnter() {
    lottie?.play();
  }

  function handleMouseOut() {
    lottie?.stop();
  }

  function openGitHub() {
    window.open('https://github.com/abyssfinance/DAICO-Smart-Contract', '_blank');
  }

  function openLink(href: string, secondButton?: boolean) {
    if (secondButton) {
      window.open(href, '_blank');
    } else {
      if (data.isTarget) {
        if (href) {
          window.open(href, '_blank');
        }
      } else {
        router.push(href);
      }
    }
  }

  if (data.isFullBlock) {
    return (
      <div
        className={classNames(
          styles.homeCardFull,
          data.isEthBlock && styles.ethCardFull,
          data.isLockup && styles.homeCardLockup
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseOut}
        onTouchStart={handleMouseEnter}
        onTouchMove={handleMouseOut}>
        <div className={styles.snips}>
          {data.snippets &&
            data.snippets.map((n: any, index: number) => {
              return (
                <div
                  className={styles.snipsItem}
                  style={{ color: n.colorText, backgroundColor: n.backgroundColor }}
                  key={index}>
                  {n.text}
                </div>
              );
            })}
        </div>

        {data.isEthBlock || data.isLockup ? (
          <img src={data.iconEth} alt="" style={{ width: '36px', height: '36px', marginBottom: 25 }} />
        ) : (
          <div
            className={isClass ? `${style} ${styles.animIcons}` : styles.animIcons}
            ref={LottieBlock}
            style={{ width: '36px', height: '36px' }}
          />
        )}

        <h3 className={data.isLockup && styles.h3MobileLockup}>
          {data.title} <p>{data.dopText}</p>
        </h3>

        <div className={classNames(styles.homeCardFullDesc, data.isLockup && styles.homeCardFullDescLockup)}>
          <p>{data.desc}</p>
        </div>

        <div
          className={classNames(styles.homeCardFullDescBy, data.isEthBlock || (data.isLockup && styles.ethHomeCard))}>
          {data.isEthBlock || data.isLockup ? (
            <Link href={data.buttonHref} legacyBehavior>
              <Button>{data.buttonTitle}</Button>
            </Link>
          ) : data.buttonHref ? (
            <Link href={data.buttonHref} legacyBehavior>
              <Button>{data.buttonTitle}</Button>
            </Link>
          ) : (
            <Button onClick={openGitHub}>{data.buttonTitle}</Button>
          )}

          {data.isEthBlock && data.depositorCurrencyId === 'pulsechain' ? (
            <Button
              type={'white'}
              className="hidden-mobile"
              onClick={data.isEthBlock ? () => setIsPulsechainDepositorModalOpened(true) : () => openGitHub()}>
              {data.button3Title}
            </Button>
          ) : data.isEthBlock ? (
            <Button
              type={'white'}
              className="hidden-mobile"
              onClick={data.isEthBlock ? () => setIsOpenInfoModal(true) : () => openGitHub()}>
              {data.button3Title}
            </Button>
          ) : null}

          {data.isEthBlock ? (
            <Button
              type={'white'}
              className="hidden-mobile"
              onClick={data.isEthBlock ? () => openLink(data.buttonHref2) : () => openGitHub()}
              styles={{ transition: 'none' }}>
              {data.button2Title}
            </Button>
          ) : null}

          {data.isLogoButton ? (
            <a
              href="https://twitter.com/newalchemy/status/974786847984570368"
              target="_blank"
              rel="noreferrer noopener">
              Audited by <NewAlchemySvg />
            </a>
          ) : null}
        </div>

        {data.bgImg ? (
          <img src={data.bgImg} alt="" className={classNames(styles.bgImg, data.isEthBlock && styles.bgImgEth)} />
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={styles.homeCard}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
      onTouchStart={handleMouseEnter}
      onTouchMove={handleMouseOut}
      // onTouchEnd={handleMouseOut}
    >
      {isOpenInfoModal ? <SmartContractInfoModal onClose={() => setIsOpenInfoModal(false)} /> : null}

      {isPulsechainDepositorModalOpened ? (
        <SmartContractInfoModal currencyId="pulsechain" onClose={() => setIsPulsechainDepositorModalOpened(false)} />
      ) : null}

      <div className={styles.snips}>
        {data.snippets &&
          data.snippets.map((n: any, index: number) => {
            return (
              <div
                className={styles.snipsItem}
                style={{ color: n.colorText, backgroundColor: n.backgroundColor }}
                key={index}>
                {n.text}
              </div>
            );
          })}
      </div>
      {/*<img src={data.urlImg} alt="" />*/}
      <div
        className={isClass ? `${style} ${styles.animIcons}` : styles.animIcons}
        ref={LottieBlock}
        style={{ width: '36px', height: '36px' }}
      />

      <div className={styles.homeCardContent} style={{ marginBottom: data.isLogoButtonDepositor && 100 }}>
        <h3>{data.title}</h3>
        <span>{data.collateral}</span>
        <p>{data.desc}</p>
      </div>

      <div className={classNames(styles.inDev, data.isLogoButtonDepositor && styles.inDevDepositor)}>
        {data.isButtonDisabled ? (
          <Tooltip title="Patience you must have" position="top" trigger="mouseenter">
            <Button disabled={true}>{data.buttonTitle}</Button>
          </Tooltip>
        ) : (
          <Button onClick={() => openLink(data.buttonHref)}>{data.buttonTitle}</Button>
        )}

        {data.buttonTitle2 && (
          <Button onClick={() => openLink(data.buttonHref2)} type={data.buttonType2}>
            {data.buttonTitle2}
          </Button>
        )}

        {data.isEthBlock ? (
          <DropDownMobileDepositor
            items={[
              {
                name: 'Smart contract info',
                onAction: () => {
                  if (data.depositorCurrencyId === 'pulsechain') {
                    setIsPulsechainDepositorModalOpened(true);
                  } else {
                    setIsOpenInfoModal(true);
                  }
                }
              },
              {
                name: 'View source code',
                onAction: () => openLink(data.buttonHref2, true)
              }
            ]}
          />
        ) : null}

        {data.isLogoButtonDepositor ? (
          <a
            href="https://abyss.finance/audit/eth2depositor_security_audit_mixbytes.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.logoHomeDepositorMixBytes}>
            Audited by <LogoMixBytes />
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default HomeCard;

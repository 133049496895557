import { NotMetaMaskError, UnsupportedChainIdError, UnsupportedWalletError } from '@features/web3/types';

interface Response {
  isKnownError: boolean;
  isNotMetaMaskError: boolean;
  isUnsupportedWalletError: boolean;
  isUserRejectedRequestError: boolean;
  isUnsupportedChainIdError: boolean;
  isPendingMetaMaskError: boolean;
  title: string;
  description: string;
}

/**
 *
 * @param err
 */
export function classifyError(err: unknown): Response {
  const error = err as Error;
  const message = error?.message?.toLowerCase?.();
  const isNotMetaMaskError = err instanceof NotMetaMaskError;
  const isUnsupportedWalletError = err instanceof UnsupportedWalletError;

  const isUserRejectedRequestError =
    message?.includes?.(`user`.toLowerCase()) && message?.includes?.(`rejected`.toLowerCase());

  const isUnsupportedChainIdError = err instanceof UnsupportedChainIdError;

  const isPendingMetaMaskError = message?.includes?.(
    `Request of type 'wallet_requestPermissions' already pending`.toLowerCase()
  );

  const isKnownError =
    isNotMetaMaskError ||
    isUnsupportedWalletError ||
    isUserRejectedRequestError ||
    isUnsupportedChainIdError ||
    isPendingMetaMaskError;

  function makeMessage(): { title: string; description: string } {
    if (isPendingMetaMaskError) {
      return { title: 'Error', description: 'Request already pending. Check your wallet' };
    } else if (isUnsupportedChainIdError) {
      return { title: 'Error', description: 'ONLY MAINNET/GÖRLI ARE AVAILABLE' };
    } else if (isUserRejectedRequestError) {
      return { title: 'Error', description: 'Error connecting' };
    } else {
      return { title: 'Error', description: 'Error connecting' };
    }
  }

  const { title, description } = makeMessage();

  return {
    isKnownError: isKnownError,
    isNotMetaMaskError,
    isUnsupportedWalletError,
    isUserRejectedRequestError,
    isUnsupportedChainIdError,
    isPendingMetaMaskError,
    title,
    description
  };
}

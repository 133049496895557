import { DEPOSITOR_ADDRESSES } from '@features/eth2depositor/constants';
import { ModalPortal } from '@features/modal/ui/modal';
import { WEB3_CHAIN_DATA } from '@features/web3/constants';
import { Web3ChainId } from '@features/web3/types';
import { makeAddressUrl } from '@features/web3/utils/make-address-url';
import { CopyLink } from '@uikit/copy-link';
import { EtherscanLink } from '@uikit/etherscan-link';
import styles from './styles.module.scss';

const SmartContractInfoModal = (props: any) => {
  if (props.currencyId === 'pulsechain') {
    return (
      <ModalPortal title={'Smart contract info'} onClose={props.onClose}>
        <div className={styles.smartContractInfoModal}>
          <div className={styles.smartContractInfoModalItem}>
            <span>{WEB3_CHAIN_DATA[Web3ChainId.PulsechainMainnet].title}</span>

            <p>{DEPOSITOR_ADDRESSES.pulsechainMainnet}</p>

            <div className={styles.actionsContractInfo}>
              <div className={styles.actionsContractInfoItem}>
                <CopyLink title="Copy Address" value={DEPOSITOR_ADDRESSES.pulsechainMainnet} />
              </div>

              <div className={styles.actionsContractInfoItem}>
                <EtherscanLink
                  title="View on PulseChain Explorer"
                  value={makeAddressUrl(Web3ChainId.PulsechainMainnet, DEPOSITOR_ADDRESSES.pulsechainMainnet)}
                />
              </div>
            </div>
          </div>

          <div className={styles.delimiter} />

          <div className={styles.smartContractInfoModalItem}>
            <span>{WEB3_CHAIN_DATA[Web3ChainId.PulsechainTestnet].title}</span>

            <p>{DEPOSITOR_ADDRESSES.pulsechainTestnet}</p>

            <div className={styles.actionsContractInfo}>
              <div className={styles.actionsContractInfoItem}>
                <CopyLink title="Copy Address" value={DEPOSITOR_ADDRESSES.pulsechainTestnet} />
              </div>

              <div className={styles.actionsContractInfoItem}>
                <EtherscanLink
                  title="View on PulseChain Explorer"
                  value={makeAddressUrl(Web3ChainId.PulsechainTestnet, DEPOSITOR_ADDRESSES.pulsechainTestnet)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    );
  }

  return (
    <ModalPortal title={'Smart contract info'} onClose={props.onClose}>
      <div className={styles.smartContractInfoModal}>
        <div className={styles.smartContractInfoModalItem}>
          <span>{WEB3_CHAIN_DATA[Web3ChainId.Mainnet].title}</span>

          <p>{DEPOSITOR_ADDRESSES.mainnet}</p>

          <div className={styles.actionsContractInfo}>
            <div className={styles.actionsContractInfoItem}>
              <CopyLink title="Copy Address" value={DEPOSITOR_ADDRESSES.mainnet} />
            </div>

            <div className={styles.actionsContractInfoItem}>
              <EtherscanLink
                title="View on Etherscan"
                value={makeAddressUrl(Web3ChainId.Mainnet, DEPOSITOR_ADDRESSES.mainnet)}
              />
            </div>
          </div>
        </div>

        <div className={styles.delimiter} />

        <div className={styles.smartContractInfoModalItem}>
          <span>{WEB3_CHAIN_DATA[Web3ChainId.Holesky].title}</span>

          <p>{DEPOSITOR_ADDRESSES['holesky']}</p>

          <div className={styles.actionsContractInfo}>
            <div className={styles.actionsContractInfoItem}>
              <CopyLink title="Copy Address" value={DEPOSITOR_ADDRESSES['holesky']} />
            </div>

            <div className={styles.actionsContractInfoItem}>
              <EtherscanLink
                title="View on Etherscan"
                value={makeAddressUrl(Web3ChainId.Holesky, DEPOSITOR_ADDRESSES['holesky'])}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default SmartContractInfoModal;

import { Ledger } from '@allnodes/ledger-connector';
import { initializeConnector } from '@web3-react/core';
import { URLS } from '../chains';
import { ledgerEthereumClientFactoryAsyncAny } from '../ledger-ethereum-client-factory-async';

export const [ledgerConnector, ledgerConnectorHooks] = initializeConnector<Ledger>(actions => {
  return new Ledger({
    actions,
    options: {
      pollingInterval: 1000 * 60 * 60 * 24,
      rpcMap: URLS,
      accountFetchingConfigs: {},
      ledgerEthereumClientFactoryAsync: ledgerEthereumClientFactoryAsyncAny
    }
  });
});

import NavAnimatedIcon from './nav-animated-icon';
import styles from './styles.module.scss';

function NavPopupProducts() {
  return (
    <div className={styles.trigger}>
      <div className={styles.popupProducts} style={{ visibility: 'hidden' }} id="popupProducts">
        <img src="/img/tongue.svg" alt="" className={styles.popupServicesArrow} />

        <div className={styles.popupProductsItem} style={{ gridArea: 'abyss' }}>
          <h2>The Abyss</h2>

          <NavAnimatedIcon
            title="Gaming platform"
            path="/lottie/abyss.json"
            style={styles.abyssImg}
            queryBlock={'Abyss'}
          />

          <NavAnimatedIcon
            title="Marketplace"
            path="/lottie/market.json"
            style={styles.marketplaceImg}
            snipets={[
              {
                color: '#4782f7',
                text: 'NFT'
              }
            ]}
            queryBlock={'Abyss'}
          />
        </div>

        <div className={styles.popupProductsItem} style={{ gridArea: 'ethstaking' }}>
          <h2>DeFi</h2>

          <NavAnimatedIcon
            title="Ethereum Depositor"
            path="/lottie/depositor.json"
            style={styles.depositorImg}
            queryBlock={'DeFi'}
            snipets={[
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />

          <NavAnimatedIcon
            title="PulseChain Depositor"
            path="/lottie/depositor.json"
            style={styles.depositorImg}
            queryBlock={'DeFi'}
            snipets={[
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />

          <NavAnimatedIcon
            title="Free Hosting Service"
            path="/lottie/eth.json"
            style={styles.ethImg}
            queryBlock={'DeFi'}
            snipets={[
              {
                color: 'rgb(138, 101, 247)',
                text: 'ETHEREUM'
              },
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />
        </div>

        <div className={styles.popupProductsItem} style={{ gridArea: 'masternodes', marginTop: 40 }}>
          <h2>Abyss Masternodes</h2>

          <NavAnimatedIcon
            title="Lunar nodes"
            path="/lottie/data.json"
            queryBlock="Masternodes"
            style={styles.lightnodesImg}
          />

          <NavAnimatedIcon
            title="Solar nodes"
            path="/lottie/darknodes.json"
            queryBlock="Masternodes"
            style={styles.darknodesImg}
          />
        </div>

        <div className={styles.popupProductsItem} style={{ gridArea: 'relative-block', marginTop: 40 }}>
          <h2>ABYSS STAKING</h2>

          <NavAnimatedIcon
            title="Guilds"
            path="/lottie/pure.json"
            queryBlock="Staking"
            style={styles.pureImg}
            snipets={[
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />

          <NavAnimatedIcon
            title="Syndicates"
            path="/lottie/syndicates.json"
            queryBlock="Staking"
            style={styles.syndicatesImg}
            snipets={[
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />
        </div>

        <div className={styles.popupProductsItem} style={{ gridArea: 'crowdfunding' }}>
          <h2>Crowdfunding</h2>

          <NavAnimatedIcon
            title="DAICO"
            path="/lottie/daico.json"
            queryBlock="Crowdfunding"
            style={styles.daicoImg}
            snipets={[
              {
                color: '#00bac2',
                text: 'DEFI'
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default NavPopupProducts;

import { modalActions } from '@features/modal/state/actions';
import { web3Actions } from '@features/web3/state/actions';
import { TransactionMode, Web3ChainId, Web3ConnectStatus, Web3WalletType } from '@features/web3/types';
import { createReducer } from '@reduxjs/toolkit';

/**
 *
 */
export interface Web3State {
  connectStatus: Web3ConnectStatus;
  walletType: Web3WalletType | null;
  account: string | null;
  chainId: Web3ChainId | null;
  balance: string | null;
  baseDerivationPath: string | null;
  isManualTransactionAllowed: boolean;
  transactionMode: TransactionMode;
}

/**
 *
 */
const INITIAL_STATE: Web3State = {
  connectStatus: Web3ConnectStatus.Unknown,
  walletType: null,
  account: null,
  chainId: null,
  balance: null,
  baseDerivationPath: null,
  isManualTransactionAllowed: false,
  transactionMode: TransactionMode.Auto
};

/**
 *
 */
export const web3Reducer = createReducer<Web3State>(INITIAL_STATE, builder => {
  builder
    .addCase(web3Actions.connecting, (state, action) => {
      state.connectStatus = Web3ConnectStatus.Connecting;
      state.walletType = action.payload.walletType;
      state.account = null;
      state.chainId = null;
      state.balance = null;

      if (action.payload.baseDerivationPath) {
        state.baseDerivationPath = action.payload.baseDerivationPath;
      }
    })
    .addCase(web3Actions.connected, (state, action) => {
      state.connectStatus = Web3ConnectStatus.Connected;
      state.account = action.payload.account;
      state.chainId = action.payload.chainId;
    })
    .addCase(web3Actions.disconnected, state => {
      state.connectStatus = Web3ConnectStatus.Disconnected;
      state.walletType = null;
      state.account = null;
      state.chainId = null;
      state.balance = null;
      state.baseDerivationPath = null;
    })
    .addCase(web3Actions.setBalance, (state, action) => {
      if (state.connectStatus === Web3ConnectStatus.Connected) {
        state.balance = action.payload.balance;
      }
    })
    .addCase(web3Actions.changedAccount, (state, action) => {
      state.account = action.payload.account;
      state.balance = null;
    })
    .addCase(web3Actions.changedChainId, (state, action) => {
      state.chainId = action.payload.chainId;
      state.balance = null;
    })
    .addCase(modalActions.openWalletConnectModal, (state, action) => {
      state.isManualTransactionAllowed = action.payload?.isManualTransactionAllowed ?? false;
    })
    .addCase(web3Actions.setTransactionMode, (state, action) => {
      state.transactionMode = action.payload.transactionMode;
    });
});

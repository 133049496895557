import React from 'react';

/**
 *
 */
export interface Notification {
  type: 'success' | 'failure';
  message: string;
}

/**
 *
 */
export interface NotificationValue {
  notification: Notification | null;
  pushNotification: (notification: Notification, timeout?: number) => void;
}

/**
 *
 */
export const NotificationContext = React.createContext<NotificationValue>({
  notification: null,
  pushNotification: () => {}
});

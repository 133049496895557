/**
 *
 * @param value
 */
export function copy(value: string): void {
  const textarea = document.createElement('textarea');

  textarea.value = value;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';

  document.body.appendChild(textarea);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const editable = textarea.contentEditable;
    const readOnly = textarea.readOnly;

    textarea.contentEditable = 'true';
    textarea.readOnly = true;

    const range = document.createRange();

    range.selectNodeContents(textarea);

    const selection = window.getSelection();

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    textarea.setSelectionRange(0, 999_999);

    textarea.contentEditable = editable;
    textarea.readOnly = readOnly;
  } else {
    textarea.select();
  }

  document.execCommand('copy');
  document.body.removeChild(textarea);
}

import { ServiceId } from '@features/web3/types';

/**
 *
 * @param pathname
 */
export function getServiceIdByPathname(pathname: string): ServiceId {
  switch (pathname) {
    case '/lockup': {
      return ServiceId.Lockup;
    }

    case '/hosting': {
      return ServiceId.Hosting;
    }

    case '/host': {
      return ServiceId.Host;
    }

    case '/dashboard': {
      return ServiceId.Dashboard;
    }

    case '/eth2depositor': {
      return ServiceId.Depositor;
    }

    case '/pls-depositor': {
      return ServiceId.PulsechainDepositor;
    }

    case '/': {
      return ServiceId.Home;
    }

    default: {
      return ServiceId.Main;
    }
  }
}
